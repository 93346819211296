import React from "react";
import { BrowserRouter as Routers, Routes, Route } from "react-router-dom";
import CustomerSignUp from "./Routes/Customer/Auth/SignUp";
import CustomerSignIn from "./Routes/Customer/Auth/SignIn";
import VendorOnboard from "./Routes/Vendor/Onboard";
import VendorHome from "./Routes/Vendor/Home";
import Leads from "./Routes/Vendor/Dashboard/Leads";
import Layout from "./Layouts/Layout";
import Private from "./Layouts/Private";
import DashboardLayout from "./Layouts/DashboardLayout";
import Test from "./components/Page/test";
import Proposals from "./Routes/Vendor/Dashboard/Proposals";
import Proposal from "./Routes/Vendor/Dashboard/Proposal";
import NotFound from "./components/Common/NotFound";
import ProjectTracker from "./Routes/Vendor/Dashboard/ProjectTracker";
import LeadForm from "./Routes/Vendor/Dashboard/LeadForm";
import Projects from "./Routes/Vendor/Dashboard/Projects";
import PendingLeads from "./Routes/Vendor/Dashboard/PendingLeads";

function App() {
  return (
    <div className="App">
      <Routers>
        <Routes>
          {/* Auth */}
          <Route path="/signup" Component={CustomerSignUp} />
          <Route path="/login" Component={CustomerSignIn} />

          {/* <Route element={<Private />}> */}
          <Route path="/onboard" Component={VendorOnboard} />
          {/* </Route> */}

          {/* Test */}
          <Route path="/test" Component={Test} />

          {/*Common */}
          <Route element={<Layout />}>
            <Route path="/" Component={VendorHome} />
          </Route>
          {/* Vendor Dashboard */}
          <Route element={<DashboardLayout />}>
            <Route path="/dashboard/projects" Component={Projects} />
            <Route
              path="/dashboard/projects/:projectId"
              Component={ProjectTracker}
            />
            <Route path="/dashboard/leads" Component={Leads} />
            <Route path="/dashboard/pending-leads" Component={PendingLeads} />
            <Route path="/dashboard/lead/:leadId" Component={LeadForm} />
            <Route path="/dashboard/proposals" Component={Proposals} />
            <Route
              path="/dashboard/proposals/proposalManager"
              Component={Proposal}
            />
          </Route>
          {/* <Route path="*" Component={NotFound} /> */}
        </Routes>
      </Routers>
    </div>
  );
}

export default App;
