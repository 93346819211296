import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OTPInput from "./OTPINput";
import axios from "axios";

const LoginForm = ({ setActiveForm, closeModal }) => {
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    phoneNumber: "",
  });
  const [submitOTP, setSubmitOTP] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = (event) => {
    event.preventDefault();
    setShowPassword((prevState) => !prevState);
  };

  const verifyOnboard = async (accessToken) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/vendor`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data) {
          closeModal();
          navigate("/dashboard/leads");
        }
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        closeModal();
        navigate("/dashboard/leads");
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const getOTP = async (event) => {
    event.preventDefault();

    const phoneNumber = formValues.phoneNumber;
    const numericRegex = /^(0|91)?[6-9][0-9]{9}$/;
    if (!numericRegex.test(phoneNumber) || phoneNumber === "") {
      setError("Invalid Phone Number");
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER}/auth/send-otp`,
          { phoneNumber: `+91${phoneNumber}` }
        );

        if (response.status === 200) {
          setSubmitOTP(true);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
        } else {
          setError("An error occurred. Please try again later.");
        }
      }
    }
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    const { email, password } = formValues;

    if (email === "" || password === "") {
      setError("Please fill in all required fields.");
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER}/auth/authenticate`,
          { email, password }
        );

        if (response.status === 200) {
          localStorage.setItem("Authorization", response.data.access_token);
          verifyOnboard(response.data.access_token);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
        } else {
          setError("An error occurred. Please try again later.");
        }
      }
    }
  };

  return (
    <div>
      {/* Login Form */}
      <form onSubmit={handleLoginSubmit}>
        <div className="mt-4">
          <label htmlFor="email" className="block text-sm text-gray-700">
            Email*
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formValues.email}
            onChange={handleChange}
            className="mt-1 px-3 py-2 w-full border-2 text-medium border-gray-300 outline-primary rounded-md"
            placeholder="Enter Email"
          />
        </div>
        <div className="mt-4">
          <label htmlFor="password" className="block text-sm text-gray-700">
            Password*
          </label>
          <div className="flex mt-1 px-3 py-2 w-full border-2 text-medium border-gray-300 outline-primary rounded-md">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              onChange={handleChange}
              name="password"
              className="w-full border-none outline-none"
              placeholder="Enter Password"
            />
            <button
              onClick={togglePasswordVisibility}
              className="absolute right-0 px-10"
            >
              {showPassword ? (
                <img
                  src="/open-eye.png"
                  alt="Hide password"
                  className="w-6 h-6"
                />
              ) : (
                <img
                  src="/close-eye.png"
                  alt="Show password"
                  className="w-6 h-6"
                />
              )}
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center mt-5">
          {/* Forgot Password */}
          {/* <p className="text-sm  font-bold text-primary cursor-pointer">
            Forgot Password ?
          </p> */}
          {/* Login Button */}
          <button
            type="submit"
            className="bg-blue-800 text-white text-sm px-5 py-2 rounded-3xl hover:bg-blue-600 shadow-xl"
          >
            Login
          </button>
        </div>
      </form>

      {/* OR Separator */}
      <div className="flex items-center mt-5">
        <div className="border-t border-gray-300 flex-grow mr-3"></div>
        <span className="text-sm text-gray-500">OR</span>
        <div className="border-t border-gray-300 flex-grow ml-3"></div>
      </div>

      {/* Conditional rendering for OTP input */}
      {submitOTP ? (
        <OTPInput
          setError={setError}
          phoneNumber={formValues?.phoneNumber}
          verifyOnboard={verifyOnboard}
          getOTP={getOTP}
        />
      ) : (
        <form onSubmit={getOTP} className="mt-4">
          {/* Input field for phone number */}
          <label htmlFor="phone" className="block text-sm text-gray-700">
            Phone*
          </label>
          <div className="flex mt-1 px-3 py-1 w-full border-2 text-medium border-gray-300 outline-primary rounded-md">
            <input
              type="text"
              id="phone"
              name="phoneNumber"
              maxLength={10}
              onChange={handleChange}
              className="w-full border-none outline-none"
              placeholder="Enter Phone Number"
              pattern="^(0|91)?[6-9][0-9]{9}$"
            />
            <button
              type="submit"
              className="bg-blue-800 text-white text-sm px-4 py-2 rounded-3xl hover:bg-blue-600 shadow-xl"
            >
              OTP
            </button>
          </div>
        </form>
      )}

      {error && <p className="text-red-500 mt-4">{error}</p>}
      <p className="text-sm  text-gray-700 mt-4">
        Haven't registered yet?
        <span
          className="font-bold cursor-pointer text-primary"
          onClick={() => setActiveForm(true)}
        >
          {" "}
          Create an account
        </span>
      </p>
    </div>
  );
};

export default LoginForm;
