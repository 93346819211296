import React, { useState } from "react";
import ProposalItem from "../../../components/Dashboard/ProposalItem";
import ChatModal from "../../../components/Dashboard/ChatModal";

const Proposal = (props) => {
  const [modal, setModal] = useState(false);

  return (
    <div
      className="relative w-full px-14 mt-5 gap-8"
      style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
    >
      <h1 className="mb-4">All Proposals</h1>
      <ProposalItem />
      <ProposalItem />

      {modal ? (
        <ChatModal setModal={setModal} />
      ) : (
        <div className="fixed bottom-10 right-10 z-50 rounded-full shadow-lg">
          <button
            onClick={() => {
              setModal(true);
            }}
            className="w-16 h-16 rounded-full bg-secondary text-white border-none cursor-pointer flex justify-center items-center"
          >
            <img src="/message.png" alt="chat" className="w-8 h-8" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Proposal;
