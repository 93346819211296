import React from "react";

const ProposalItem = ({ fileName, uploadDate }) => {
  return (
    <div className="flex bg-white border border-gray-300 rounded-md px-4 py-3 my-3 w-3/4">
      <div className="flex mt-2 justify-start">
        <img src="/pdf.png" alt="PDF Icon" className="w-8 h-8 mr-2" />
      </div>
      <div className="flex flex-col flex-grow mx-4">
        <p className="text-lg">TanmayProposal</p>
        <div className="flex gap-5">
          <p className="text-sm text-gray-500">24 Feb 2023</p>
          <p className="text-sm text-gray-500 underline cursor-pointer">
            Download
          </p>
        </div>
      </div>
      <div className="border rounded-md p-2">
        <textarea
          className="rounded-md py-2 px-3 mr-2 w-80 h-32 overflow-y-auto resize-none outline-none"
          placeholder="Add a comment..."
        />
        <button className="bg-transparent text-white rounded-md hover:bg-white hover:text-primary text-md m-2 flex-grow-0">
          <img src="/send.png" alt="send" className="w-5 h-5" />
        </button>
      </div>

      {/* <div className="flex items-center justify-end">
        <img src="/download.png" alt="Download Icon" className="w-8 h-8 ml-2" />
      </div> */}
    </div>
  );
};

export default ProposalItem;
