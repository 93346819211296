import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer className="text-xl bg-blue-50 text-blue-600 lg:text-sm bg-grey-500 mt-20">
        <div className="w-full p-4 py-6 mx-auto lg:py-8">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0">
              <Link href="/" className="flex items-center">
                <img
                  src="/logo_header.png"
                  className="h-12 mr-3"
                  alt="EnergyBae Logo"
                  width={400}
                  height={220}
                />
              </Link>
              <div className="mt-8 text-2xl font-semibold text-blue-600 lg:text-xl">
                Follow Us!
              </div>
              <div className="flex justify-start mt-2">
                <Link
                  to={"https://www.instagram.com/energybae.co/"}
                  className="pr-2"
                >
                  {" "}
                  <img className="w-8" alt="x" src="/instagram.svg" />
                </Link>
                <Link
                  to={"https://www.facebook.com/energybae.co"}
                  className="px-2"
                >
                  {" "}
                  <img className="w-8" alt="x" src="/fb.png" />
                </Link>
                <Link to="https://twitter.com/energybae" className="px-2">
                  <img className="w-8" alt="x" src="/twitter.svg" />
                </Link>
                <Link
                  to={"https://www.linkedin.com/company/energybae/"}
                  className="px-2"
                >
                  {" "}
                  <img className="w-8" alt="x" src="/linkedin.png" />
                </Link>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
              <div>
                <h2 className="mb-6 text-xl font-semibold text-blue-600 uppercase ">
                  Solutions
                </h2>
                <ul className="font-medium text-blue-600 ">
                  <li className="mb-4">
                    <Link
                      to="https://energybae.in/"
                      className="hover:underline"
                    >
                      Hybrid
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link
                      to="https://energybae.in/"
                      className="hover:underline"
                    >
                      Solar
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link
                      href="https://energybae.in/"
                      className="hover:underline"
                    >
                      Wind
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-xl font-semibold text-blue-700 uppercase">
                  About us
                </h2>
                <ul className="font-medium text-blue-600 ">
                  <li className="mb-4">
                    <Link href="/" className="hover:underline ">
                      Blogs
                    </Link>
                  </li>
                  <li>
                    <Link href="/" className="hover:underline">
                      Trainings
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-xl font-semibold text-blue-700 uppercase ">
                  Contact Us
                </h2>
                <ul className="font-medium text-blue-600 ">
                  <li className="mb-4 group">
                    <p>
                      Call us on{" "}
                      <span className="group-hover:underline break-words">
                        +91 7744977420/9112233120
                      </span>
                    </p>
                  </li>
                  <li className="mb-4">
                    <Link href="#" className="hover:underline break-words">
                      freeenergy@energybae.in
                    </Link>
                  </li>
                  <li>
                    <Link href="#" className="hover:underline">
                      Pune, Maharashtra, India
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              © 2024{" "}
              <Link href="https://energybae.in/" className="hover:underline">
                EnergyBae
              </Link>
              . All Rights Reserved.
            </span>
            <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
              {/* <Link
                href="/about"
                className="text-blue-500 hover:text-gray-900 dark:hover:text-white"
              >
                <span className="">About Us</span>
              </Link>
              <Link
                href="/services"
                className="text-blue-500 hover:text-gray-900 dark:hover:text-white"
              >
                <span className="">Services</span>
              </Link>
              <Link
                href="/contact"
                className="text-blue-500 hover:text-gray-900 dark:hover:text-white"
              >
                <span className="">Contact</span>
              </Link> */}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
