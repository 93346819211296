import ProposalCard from "../../../components/Dashboard/ProposalCard";
import React from "react";

const Proposals = (props) => {
  return (
    <div className="grid grid-cols-3 gap-4 p-5 mt-8">
      <ProposalCard />
      <ProposalCard />
      <ProposalCard />
      <ProposalCard />
    </div>
  );
};

export default Proposals;
