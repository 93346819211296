import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Box, IconButton, Typography, MenuItem, Popover } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';


const ProfileHeader = ({ title, view, currRoute, profile }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem("Authorization");
    navigate("/");
  };

  const open = Boolean(anchorEl);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (isOpen && !event.target.closest(".dropdown")) {
  //       closeDropdown();
  //     }
  //   };

  //   window.addEventListener("click", handleClickOutside);
  //   return () => {
  //     window.removeEventListener("click", handleClickOutside);
  //   };
  // }, [isOpen]);

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#EEF5FF', borderRadius: '20px' }}>
      <Toolbar className="flex justify-between">
        {view ? (
          <Box className="flex items-center">
            <NavLink to="/dashboard/leads" className="flex items-center">
              <IconButton>
                <ArrowBackIcon fontSize="large" />
              </IconButton>
              <Typography variant="h6" sx={{ color: 'black' }}>
                Manager
              </Typography>
            </NavLink>
          </Box>
        ) : (
          <Typography variant="h6" sx={{ color: 'black' }}>
            {title}
          </Typography>
        )}

        <Box sx={{ display: 'flex', alignItems: 'center', color: 'black' }}> 
         <Typography variant="body1" sx={{ color: 'black' }}>
         <PersonIcon sx={{ mr:1, fontSize: 20 }}/>
              {profile?.firstname}
            </Typography>
          <IconButton color="inherit" onClick={handleLogout} sx={{ marginLeft: 1}}>
            <LogoutIcon />
          </IconButton>
        </Box>
        
        {/* <Box className="flex items-center">
          <Box className="flex items-center">
            {/* <img className="pr-2 w-10 h-7" src="/man.png" alt="profile_img" /> */}
            {/* <Typography variant="body1" sx={{ color: 'black' }}> */}
              {/* {profile?.firstname} */}
            {/* </Typography> */}
          {/* </Box> */}
          {/* <IconButton
            onClick={handlePopoverOpen}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <ArrowDropDownIcon />
          </IconButton> */}
          {/* <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                borderRadius: '20px',
                overflow: 'hidden',
                backgroundColor: '#EEF5FF',
                width: '180px'
              },
            }}
          >
            <Box sx={{ p: 2 }}>
              <MenuItem
                component={NavLink}
                to="/profile"
                onClick={handlePopoverClose}
                sx={{ color: 'black' }}
              >
                <PersonIcon sx={{ mr:1, fontSize: 20 }}/>
                My Profile
              </MenuItem>
              <MenuItem onClick={handleLogout} sx={{ color: 'black' }}>
                <LogoutIcon sx={{ mr:1, fontSize: 20 }}/>
                Logout
              </MenuItem>
              {/* <Button onClick={handlePopoverClose} variant="outlined" color="primary">
                Close
              </Button> */}
            {/* </Box> */}
          {/* </Popover> */} 
        {/* </Box> */}

        
      </Toolbar>
    </AppBar>
  );
};

export default ProfileHeader;
