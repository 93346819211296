import axios from "axios";
import React, { useState, useEffect, useRef } from "react";

const OTPInput = ({ setError, phoneNumber, verifyOnboard, getOTP }) => {
  const [otp, setOTP] = useState(["", "", "", ""]);
  const firstInputRef = useRef(null);

  useEffect(() => {
    // Set focus on the first input box when component is initially rendered
    firstInputRef.current.focus();
  }, []);

  const handleChange = (index, event) => {
    const { value } = event.target;
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);
      if (value !== "" && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleSubmit = async () => {
    if (phoneNumber.length !== 10) {
      setError("Phone Number must be 10 digit.");
    } else if (otp.includes("")) {
      setError("Invalid OTP");
    } else {
      let checkOtp = "";
      for (var i = 0; i < 4; i++) {
        checkOtp += otp[i];
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER}/auth/authenticate-with-otp`,
          { phoneNumber: `+91${phoneNumber}`, otp: checkOtp }
        );

        if (response.status === 200) {
          localStorage.setItem("Authorization", response.data.access_token);
          verifyOnboard(response.data.access_token);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
        } else {
          setError("An error occurred. Please try again later.");
        }
      }
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex justify-center mt-4">
        {otp.map((digit, index) => (
          <input
            ref={index === 0 ? firstInputRef : null}
            key={index}
            id={`otp-input-${index}`}
            type="text"
            maxLength={1}
            className="w-16 h-16 text-4xl border rounded mx-1 text-center"
            value={digit}
            onChange={(event) => handleChange(index, event)}
            onKeyDown={(event) => handleKeyDown(index, event)}
          />
        ))}
      </div>
      <div className="flex ">
        <div className=" mt-4 pr-28">
          <p onClick={getOTP} className="text-green-800 cursor-pointer text-sm font-bold">
            Resend otp
          </p>
        </div>
        <button
          onClick={handleSubmit}
          className="mt-4 ml-5 bg-blue-800 text-white text-sm px-5 py-2 rounded-3xl hover:bg-blue-600 shadow-xl self-end"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default OTPInput;
