import React, { useState } from "react";
import VendorHomeBanner from "../../components/Page/VendorHomeBanner";
import ServiceCard from "../../components/Page/ServiceCard";
import Footer from "../../components/Common/Footer";

const VendorHome = () => {
  const [selectedManager, setSelectedManager] = useState("project"); // Set initial value to "project"

  const handleManagerClick = (managerType) => {
    setSelectedManager(managerType);
  };
  return (
    <div>
      {/* Banner */}
      <VendorHomeBanner />

      {/* Services */}
      <h1 className="font-bold text-primary text-3xl text-center mt-16">
        What We Offer
      </h1>
      <div className="flex items-center justify-center mt-10">
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-16 ">
          <ServiceCard  title="Project Management" Text="Effortlessly oversee your solar and wind projects with our intuitive project tracking module. Stay organised, stay on track, and achieve your goals with ease."/>
          <ServiceCard title= "Support" Text="Leverage the EnergyBae network for end-to-end assistance, spanning procurement to installation. Benefit from our extensive resources and expertise every step of the way." />
          <ServiceCard title= "Leads" Text="Unlock the potential of our lead generation services tailored to nurture and qualify prospects for your business. Our approach streamlines your efforts, ensuring each lead is primed for conversion." />
          <ServiceCard title= "Finance" Text="Unlock the power of solar energy for your home hassle-free with our comprehensive CRM platform. Connect with trusted vendors who offer tailored solutions to meet your energy needs."/>
        </div>
      </div>

      {/* Dashboard Overview */}
      <h1 className="font-bold text-primary text-3xl text-center mt-20">
      Level Up Your Business with EnergyBae.
      </h1>
      <div className="flex items-center justify-center mt-10 flex-col mb-20">
        {/* Button Group */}
        <div className="flex space-x-20">
          <button
            className={`bg-transparent font-bold border-2 border-primary text-primary py-1 px-4 rounded-md hover:bg-primary hover:text-white text-lg ${
              selectedManager === "project" ? "bg-green-500" : "bg-white"
            }`}
            onClick={() => handleManagerClick("project")}
          >
            Project Manager
          </button>
          <button
            className={`bg-transparent font-bold border-2 border-primary text-primary py-1 px-4 rounded-md hover:bg-primary hover:text-white text-lg ${
              selectedManager === "lead" ? "bg-green-500" : "bg-white"
            }`}
            onClick={() => handleManagerClick("lead")}
          >
            Leads Manager
          </button>
          <button
            className={`bg-transparent font-bold border-2 border-primary text-primary py-1 px-4 rounded-md hover:bg-primary hover:text-white text-lg ${
              selectedManager === "task" ? "bg-green-500" : "bg-white"
            }`}
            onClick={() => handleManagerClick("task")}
          >
            Task Manager
          </button>
        </div>

        {/* Render Image based on selected manager */}
        <div className="relative flex items-center justify-center mt-10">
          {/* Render image based on selected manager */}
          {selectedManager === "project" && (
            <img
              src="/dashboard-project.png"
              alt="dashboard-img"
              className="w-2/3 h-auto object-cover border-2 rounded-2xl"
            />
          )}
          {selectedManager === "lead" && (
            <img
              src="/dashboard-lead.png"
              alt="dashboard-img"
              className="w-2/3 h-auto object-cover border-2 rounded-2xl"
            />
          )}
          {selectedManager === "task" && (
            <img
              src="/task-dashboard.svg"
              alt="dashboard-img"
              className="w-2/3 h-auto object-cover border-2 rounded-2xl"
            />
          )}
        </div>

        {/* Description */}
        <div className="w-1/2 text-xl text-center text-gray-600 mt-10">
          <h1>
            A task manager is a software tool or application designed to help
            individuals or teams organize, prioritize, and track tasks, ensuring
            efficient workflow and project management.
          </h1>
        </div>
      </div>
    </div>
  );
};

export default VendorHome;
