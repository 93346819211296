import Header from "../components/Common/Header";
import { Outlet } from "react-router-dom";
import Footer from "../components/Common/Footer";

const Layout = (props) => {
  return (
    <div className="min-h-screen flex justify-center">
      <div className="max-w-screen-xl mx-auto w-full ml-5 mr-5 mt-4">
        <Header />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
