import { useEffect, useState } from "react";
import React from "react";
import Proposal from "../../../components/Dashboard/Proposal";
import { useParams } from "react-router-dom";
import { getLeadById, getProjectFile, uploadedAttachments } from "../../../api";
import ChatModal from "../../../components/Dashboard/ChatModal";
import { Typography, Grid, Box, Card, IconButton, Modal } from '@mui/material';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const LeadForm = () => {
  const { leadId } = useParams();
  const [lead, setLead] = useState(null);
  const [activeSection, setActiveSection] = useState([]);
  const [modal, setModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const requirements = [
    { label: "Type Of Installation", value: "typeOfInstallation" },
    { label: "Location", value: "location" },
    { label: "Pincode", value: "pincode" },
    { label: "Technology", value: "technology" },
  ];

  const siteCondition = [
    { label: "Average Electricity Bill", value: "avgBill" },
    { label: "Site Coordinates", value: "siteCoordinates" },
    { label: "No. Of Floors", value: "noOfFloors" },
    { label: "Total Flats", value: "totalFlats" },
    { label: "Roof Top Area", value: "roofTopArea" },
    { label: "Site Description", value: "siteDescription" },
  ];

  const meterDetails = [
    { label: "Meter Type", value: "meterType" },
    { label: "Number Of Meters", value: "numberOfMeter" },
    { label: "Consumer Number", value: "consumerNo" },
    { label: "Connection Type", value: "consumerType" },
    { label: "Sanctioned Load", value: "sanctionedLoad" },
    { label: "Contract Demand", value: "contractDemand" },
  ];

  const inverterDetails = [
    { label: "Capacity", value: "inverterCapacity" },
    { label: "Connection Type", value: "connectionType" },
  ];

  const solarDetails = [
    { label: "Solar Capacity", value: "solarCapacity" },
    { label: "Solar Brand", value: "solarBrand" },
    { label: "Solar Type", value: "solarType" },
  ];

  const windDetails = [
    { label: "Wind Capacity", value: "windCapacity" },
    { label: "Wind Type", value: "windType" },
    { label: "Wind Brand", value: "windBrand" },
  ];

  // only for offgrids
  const batteryDetails = [
    { label: "Battery Backup Capacity", value: "batteryBackupCapacity" },
    { label: "Battery Capacity", value: "batteryBrand" },
    { label: "Battery Type", value: "batteryType" },
    { label: "No. Of Batteries", value: "numberOfBatteries" },
    { label: "System Voltage", value: "systemVoltage" },
    { label: "Backup Power", value: "backupPower" },
  ];

  const shouldShowSolarSection = activeSection.some(
    (section) =>
      section.toLowerCase() === "solar" || section.toLowerCase() === "hybrid"
  );

  const shouldShowWindSection = activeSection.some(
    (section) =>
      section.toLowerCase() === "wind" || section.toLowerCase() === "hybrid"
  );

  const shouldShowOffGridSection = activeSection.some(
    (section) => section.toLowerCase() === "off"
  );

  const handleImageClick = async (imageUrl) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await getProjectFile(imageUrl, accessToken);
      window.open(response, "_blank");
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Error uploading file");
    }
  };

  const isVideoFile = (key) => {
    const videoExtensions = ['mp4', 'mov', 'avi', 'wmv', 'flv'];
    const extension = key.split('.').pop();
    return videoExtensions.includes(extension);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const fetchVendorLeads = async () => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await getLeadById(leadId, accessToken);
      setLead(response.project);
      const techArray = response.project.technology?.split("-");
      setActiveSection(techArray);
      fetchAttachments(response.project.projectNo);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  const fetchAttachments = async (projectNo) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await uploadedAttachments(projectNo, accessToken);
      const files = response.data.files;
      setAttachments(files);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  useEffect(() => {
    fetchVendorLeads();
  }, []);

  const DetailSection = ({ title, details, lead }) => (
    <>
    <Card sx={{ paddingX: 6, marginTop: 2,  paddingY: 3, width: '100%' }}>
      <Typography variant="h6" component="h1" gutterBottom>
        {title}
      </Typography>
      <div className="grid grid-cols-4 gap-x-20 gap-y-7 mb-4">
        {details.map((item, index) => (
          <div key={index}>
            <p className="text-gray-500 font-bold text-md mb-2">{item.label}</p>
            <p className="text-md">{lead ? (lead[item.value] ? lead[item.value] : "NA") : "Loading"}</p>
          </div>
        ))}
      </div>
      </Card>
    </>
  );

  return (
    <div className="px-6 w-full mb-6 mt-11">
    <Box sx={{ mt: 3 }}>
    <Card sx={{ paddingX: 4, marginTop: 6,  paddingY: 3 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Requirements
      </Typography>
      <Grid container spacing={2}>
        {requirements.map((item, index) => (
          <Grid item xs={12} sm={3} key={index}>
            <Box marginBottom={2}>
              <Typography variant="subtitle1" component="p" color="textSecondary" fontWeight="bold" gutterBottom>
                {item.label}
              </Typography>
              <Typography variant="body1">
                {lead ? lead[item.value] ?? "NA" : "Loading"}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Card>

    <Card sx={{ paddingX: 4, marginTop: 2, paddingY: 3 }}>
    <Grid container spacing={2}>
    <Typography variant="h5" component="h1" gutterBottom>
      Technical Details
    </Typography>
    
    <DetailSection title="Site Conditions" details={siteCondition} lead={lead} />
    <DetailSection title="Meter Details" details={meterDetails} lead={lead} />
    <DetailSection title="Inverter" details={inverterDetails} lead={lead} />

    {shouldShowSolarSection && (
      <DetailSection title="Solar" details={solarDetails} lead={lead} />
    )}

    {shouldShowWindSection && (
      <DetailSection title="Wind" details={windDetails} lead={lead} />
    )}

    {shouldShowOffGridSection && (
      <DetailSection title="Battery" details={batteryDetails} lead={lead} />
    )}

     <Grid item xs={12} sm={3}>
        <Typography variant="subtitle1" gutterBottom>
            Attachments
          </Typography>
            <Box>
              <IconButton
                onClick={handleOpen}
                className="cursor-pointer"
                sx={{ marginRight: '8px', marginBottom: '8px', width: '64px', height: '64px' }}
              >
              <PermMediaIcon sx={{ width: '100%', height: '100%' }}/>
              </IconButton>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="attachments-modal-title"
                aria-describedby="attachments-modal-description"
              >
            <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 3,
                borderRadius: 1,
              }}
            >
              <Typography variant="subtitle1" gutterBottom sx={{ textAlign: 'left' }}>
                Find Attachments
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', mt: 2 }}>
                <Grid container spacing={2} justifyContent="center">
                  {attachments.map(({ key }, index) => {
                    const isVideo = isVideoFile(key);
                    return (
                      <Grid item key={index}>
                        <IconButton
                          onClick={() => handleImageClick(key)}
                          sx={{
                            padding: '10px',
                            width: '84px',
                            height: '84px',
                            position: 'relative',
                            borderRadius: 0
                          }}
                        >
                          <img
                            src={`https://energybae-bucket.s3.ap-south-1.amazonaws.com/${key}`}
                            alt={isVideo ? "" : `Thumbnail ${index + 1}`}
                            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }}
                          />
                          {isVideo && (
                            <PlayCircleIcon
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                width: '74px',
                                height: '74px',
                                transform: 'translate(-50%, -50%)',
                                color: '#EEF5FF',
                                fontSize: 70,
                                backgroundColor: '#BED7DC',
                                borderRadius: '10px'
                              }}
                            />
                          )}
                        </IconButton>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
            </Modal>
            </Box>
          </Grid>
          </Grid>
    </Card>

      {/* <div className="flex justify-end h-fit mt-4 mr-4">
        <button className="bg-transparent border border-primary text-primary py-1 px-4 rounded-md hover:bg-secondary hover:text-white text-md">
          Book Site Visit
        </button>
        <button className="bg-secondary border border-primary text-white py-1 px-4 rounded-md hover:bg-white hover:text-primary text-md ml-4">
          Send Proposal
        </button>
      </div> */}
      <Card sx={{ p: 3, mt: 2 }}>
      <Proposal projectNo={lead?.projectNo} leadId={leadId} />
      {modal ? (
        <ChatModal projectNo={leadId} setModal={setModal} />
      ) : (
        <div className="fixed bottom-10 right-10 z-50 rounded-full shadow-lg">
          <button
            onClick={() => {
              setModal(true);
            }}
            className="w-16 h-16 rounded-full bg-secondary text-white border-none cursor-pointer flex justify-center items-center"
          >
            <img src="/message.png" alt="chat" className="w-8 h-8" />
          </button>
        </div>
      )}
      </Card>
      </Box>
    </div>
  );
};

export default LeadForm;
