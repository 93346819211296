import React from "react";

const ProposalCard = () => {
  return (
    <div className="w-full max-w-xs bg-white border border-gray-300 p-4 rounded-lg shadow-lg">
      {/* Set a fixed width */}
      <div className="flex mb-4 gap-2">
        <div className="w-3/5 mr-1">
          {" "}
          {/* Set width to 60% and reduce padding */}
          <div className="font-bold">Lead Name</div>
          <div className="text-md ">Pranay's Solar</div>
        </div>
        <div className="w-2/5">
          {" "}
          {/* Set width to 50% */}
          <div className="font-bold">Upload Date</div>
          <div className="text-md">26 Feb 2023</div>
        </div>
      </div>
      <div className="mt-2 font-bold">Recent Comment</div>
      <div className="mt-1 text-md">
        Lorem Ipsum has been the industry's standard dummy text
      </div>
    </div>
  );
};

export default ProposalCard;
