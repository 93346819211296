import { Outlet, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import ProfileHeader from "../components/Dashboard/ProfileHeader";
import Sidebar from "../components/Common/Sidebar";
import { getProfile } from "../api";

const DashboardLayout = () => {
  const location = useLocation();
  const current = location.pathname.split("/")[2];
  const currView = location.pathname.split("/")[3];
  const [section, setSection] = useState("Dashboard");
  const [profile, setProfile] = useState(null);
  const [showContent, setShowContent] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("Authorization")) {
      setShowContent(true);
    } else {
      setShowContent(false);
    }
  }, []);

  useEffect(() => {
    const fetchVendorProfile = async () => {
      const accessToken = localStorage.getItem("Authorization");
      try {
        const response = await getProfile(accessToken);
        localStorage.setItem("vendorid", response.id);
        setProfile(response);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          console.log(error.response.data.message);
        } else {
          console.log("An error occurred. Please try again later.");
        }
      }
    };
    fetchVendorProfile();
  }, []);

  useEffect(() => {
    if (current === "leads") {
      setSection("Leads");
    } else if (current === "projects") {
      setSection("Project Tracker");
    } else if (current === "pending-leads") {
      setSection("Pending Leads");
    } else {
      setSection("Dashboard");
    }
  }, [current]);

  return (
    <>
      {showContent ? (
        <div className="flex w-screen">
          <Sidebar />
          <div
            className="w-full flex-1 min-h-screen flex justify-center"
            style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
          >
            <div className="max-w-screen-xl mx-auto w-full ml-5 mr-5 mt-4">
              <ProfileHeader
                title={section}
                view={currView}
                currRoute={current}
                profile={profile}
              />
              <div className="flex justify-center">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1>Please Authenticate</h1>
      )}
    </>
  );
};

export default DashboardLayout;
