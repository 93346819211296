import OnboardForm from "../../components/Page/OnboardForm";

const VendorOnboard = () => {
  return (
    <div>
      <div className=" pl-7  flex mt-14" style={{ height: "84vh" }}>
        <div className="bg-secondary text-white rounded-2xl w-1/4 overflow-y-auto relative">
          <div className="p-6 font-bold text-2xl">
            <p>
              We're thrilled and excited that we are partnering up. This quick and easy onboarding form 
              ensures, we have essential details to kicks start our collaboration.
            </p> 
            {/* <div className="mt-4">
              <h2 className="text-lg font-bold">Instructions</h2>
              <ul className="list-disc text-base font-normal pl-6">
                <li>
                  Include your favorite emoji in the "Additional Comments"
                </li>
                <li>
                  Include your favorite emoji in the "Additional Comments"
                </li>
                <li>
                  Include your favorite emoji in the "Additional Comments"
                </li>
              </ul>
            </div> */}
          </div>
          <img
            src="/energybae-art-2.png" // Replace with the path to your image
            className="absolute bottom-0 w-full rounded-2xl"
            alt="energybae-art-2"
          />
        </div>
        <OnboardForm />
      </div>
      {/* TODO -Footer */}
    </div>
  );
};

export default VendorOnboard;
