import React from "react";

const Message = ({ user, message, time, date }) => {
  const isSentMessage = user?.sender === "vendor"; // Assuming John is the sender

  return (
    <div
      className={`flex flex-col items-${isSentMessage ? "end" : "start"} mb-4`}
    >
      <div className={`flex items-${isSentMessage ? "end" : "start"}`}>
        {!isSentMessage && (
          <img
            src={`/icon-logo.png`}
            alt="User"
            className="w-6 h-6 rounded-full mr-2"
          />
        )}
        <div className="flex flex-col">
          {!isSentMessage && <div className="text-sm pb-1">{user?.sender}</div>}
          {isSentMessage && <div className="text-sm pb-1 ml-auto">You</div>}
          <div
            className={`bg-${isSentMessage ? "secondary" : "white"} text-${
              isSentMessage ? "white" : "black"
            } p-2 rounded-xl max-w-lg`}
          >
            <p className="text-sm p-1">{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
