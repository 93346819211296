import React, { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getProjectFile,
  getProjectFileStatus,
  uploadProjectFile,
} from "../../../api";
import ChatModal from "../../../components/Dashboard/ChatModal";
import {
  Box,
  Card,
  Button,
  Typography,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow
} from "@mui/material";
const Project = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [approvalStates, setApprovalStates] = useState({});
  const [uploading, setUploading] = useState(false);
  const [fileStatus, setFileStatus] = useState([]);
  const [sectionFlag, setSectionFlag] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const section = searchParams.get("section");
  const [activeSection, setActiveSection] = useState(0);
  const [techSections, setTechSections] = useState([]);
  const [techRows, setTechRows] = useState([]);

  const solarOnGrid = [
    "Documentation",
    "MSEB Profile and Govt Subsidary",
    "Site Visit Uploads",
    "Technical Feasibility and Design",
    "Procurrent",
    "Installation",
    "Metering Process",
  ];

  const solarOffGrid = [
    "Documentation",
    "Site Visit Uploads",
    "Technical Feasibility and Design",
    "Procurrent",
    "Installation",
  ];

  const windOnGrid = [
    "Documentation",
    "MSEB Profile and Govt Subsidary",
    "Site Visit Request",
    "Technical Feasibility and Design",
    "Procurrent",
    "Installation",
    "Metering Process",
  ];

  const windOffGrid = [
    "Documentation",
    "Site Visit Request",
    "Technical Feasibility and Design",
    "Procurrent",
    "Installation",
  ];

  const hybridSolar = [
    "Documentation",
    "MSEB Profile and Govt Subsidary",
    "Site Visit Uploads",
    "Technical Feasibility and Design",
    "Procurrent",
    "Installation",
    "Metering Process",
  ];

  const hybridWind = [
    "Documentation",
    "MSEB Profile and Govt Subsidary",
    "Site Visit Uploads",
    "Technical Feasibility and Design",
    "Procurrent",
    "Installation",
    "Metering Process",
  ];

  const hybridSolarWind = [
    "Documentation",
    "MSEB Profile and Govt Subsidary",
    "Site Visit Request",
    "Technical Feasibility and Design",
    "Procurrent",
    "Installation",
    "Metering Process",
  ];

  const HybridWindSolarRow = [
    [
      {
        name: "MSEB Agreement",
        field: "mseb_agreement",
        required: false,
      },
      {
        name: "Authorisation Letter",
        field: "authorization_letter",
        required: false,
      },
      {
        name: "Electrical Contractor Licence",
        field: "electrical_contractor_license",
        required: false,
      },
      {
        name: "MSEB Payment Receipts",
        field: "mseb_payment_reciepts",
        required: false,
      },
      {
        name: "Technical Feasibility Document",
        field: "technical_feasibility",
        required: false,
      },
      {
        name: "KYC",
        field: "kyc",
        required: false,
      },
      {
        name: "Other",
        field: "other",
        required: false,
      },
    ],
    [
      {
        name: "MSEB Application",
        field: "mseb_application",
        required: false,
      },
      {
        name: "Subsidy Application",
        field: "subsidy_application",
        required: false,
      },
    ],
    [
      {
        name: "Site Visit 1",
        field: "site_visit_1",
        required: false,
      },
      {
        name: "Site Visit 2",
        field: "site_visit_2",
        required: false,
      },
    ],
    [
      {
        name: "SLD",
        field: "sld",
        required: false,
      },
      {
        name: "Plant Design",
        field: "plant_design",
        required: false,
      },
      {
        name: "Inverter Data Sheet",
        field: "inverter_data_sheet",
        required: false,
      },
      {
        name: "Panel Data Sheet",
        field: "panel_data_sheet",
        required: false,
      },
      {
        name: "Wind turbine Data sheet",
        field: "wind_turbine_data_sheet",
        required: false,
      },
    ],
    [
      {
        name: "Wind Turbine",
        field: "wind_turbine",
        required: true,
      },
      {
        name: "Wind Turbine Serial",
        field: "wind_turbine_serial",
        required: false,
      },
      {
        name: "Solar Panel",
        field: "solar_panel",
        required: true,
      },
      {
        name: "Solar Panel Serial",
        field: "solar_panel_serial",
        required: false,
      },
      {
        name: "Inverter",
        field: "inverter",
        required: true,
      },
      {
        name: "Inverter Serial",
        field: "inverter_serial",
        required: false,
      },
      {
        name: "Tower & Structure",
        field: "tower_structure",
        required: true,
      },
      {
        name: "Cables",
        field: "cables",
        required: false,
      },
      {
        name: "DCR certificate",
        field: "dcr_certificate",
        required: false,
      },
      {
        name: "Battery",
        field: "battery",
        required: true,
      },
      {
        name: "Meter",
        field: "meter",
        required: false,
      },
      {
        name: "BOS",
        field: "bos",
        required: false,
      },
    ],
    [
      {
        name: "Structure",
        field: "structure",
        type: "photo",
        multiple: true,
        required: false,
      },
      {
        name: "Installed Panel",
        field: "installed_panel",
        required: false,
      },
      {
        name: "Installed Inverter",
        field: "installed_inverter",
        required: false,
      },
      {
        name: "ACDB/DCDB",
        field: "acdb_dcdb",
        required: false,
      },
      {
        name: "Earthing Status(LA)",
        field: "earthing_status_la",
        required: false,
      },
      {
        name: "Earthing Status(Structure Earthing)",
        field: "earthing_status_structure_earthing",
        required: false,
      },
      {
        name: "Earthing Status(Earthing Pit)",
        field: "earthing_status_earthing_pit",
        required: false,
      },
      {
        name: "Earthing Status(Panel to Panel Earthing)",
        field: "earthing_status_panel_to_panel_earthing",
        required: false,
      },
      {
        name: "Earthing Status(AC/Inverter Earthing)",
        field: "earthing_status_ac_inverter_earthing",
        required: false,
      },
      {
        name: "Video Upload",
        field: "video_upload",
        type: "video",
        multiple: false,
        required: false,
      },
    ],
    [
      {
        name: "Request for QC",
        field: "request_for_qc",
        required: false,
      },
      {
        name: "MSEB QC VISIT",
        field: "mseb_qc_visit",
        required: false,
      },
      {
        name: "MSEB NOC",
        field: "mseb_noc",
        required: false,
      },
      {
        name: "Meter Testing Request",
        field: "meter_testing_request",
        required: false,
      },
      {
        name: "MSEB Testing Report",
        field: "mseb_testing_report",
        required: false,
      },
      {
        name: "MESB Release Letter",
        field: "mesb_release_letter",
        required: false,
      },
      {
        name: "MSEB Meter Installation",
        field: "mseb_meter_installation",
        required: false,
      },
      {
        name: "Send to Billing",
        field: "send_to_billing",
        required: false,
      },
    ],
  ];

  const HybridWindRow = [
    [
      {
        name: "MSEB Agreement",
        field: "mseb_agreement",
        required: false,
      },
      {
        name: "Authorisation Letter",
        field: "authorization_letter",
        required: false,
      },
      {
        name: "Electrical Contractor Licence",
        field: "electrical_contractor_license",
        required: false,
      },
      {
        name: "MSEB Payment Receipts",
        field: "mseb_payment_reciepts",
        required: false,
      },
      {
        name: "Technical Feasibility Document",
        field: "technical_feasibility",
        required: false,
      },
      {
        name: "KYC",
        field: "kyc",
        required: false,
      },
      {
        name: "Other",
        field: "other",
        required: false,
      },
    ],
    [
      {
        name: "MSEB Application",
        field: "mseb_application",
        required: false,
      },
    ],
    [
      {
        name: "Site Visit 1",
        field: "site_visit_1",
        required: false,
      },
      {
        name: "Site Visit 2",
        field: "site_visit_2",
        required: false,
      },
    ],
    [
      {
        name: "SLD",
        field: "sld",
        required: false,
      },
      {
        name: "Tower Design",
        field: "tower_design",
        required: false,
      },
      {
        name: "Inverter data sheet",
        field: "inverter_data_sheet",
        required: false,
      },
      {
        name: "Wind turbine Data sheet",
        field: "wind_turbine_data_sheet",
        required: false,
      },
    ],
    [
      {
        name: "Wind Turbine",
        field: "wind_turbine",
        required: true,
      },
      {
        name: "Wind Turbine Serial #",
        field: "wind_turbine_serial",
        required: false,
      },
      {
        name: "Inverter",
        field: "inverter",
        required: true,
      },
      {
        name: "Inverter Serial #",
        field: "inverter_serial",
        required: false,
      },
      {
        name: "Tower & Structure",
        field: "tower_structure",
        required: true,
      },
      {
        name: "Cables",
        field: "cables",
        required: false,
      },
      {
        name: "DCR certificate",
        field: "dcr_certificate",
        required: false,
      },
      {
        name: "Battery",
        field: "battery",
        required: false,
      },
      {
        name: "Battery Serial #",
        field: "battery_serial",
        required: false,
      },
      {
        name: "BOS",
        field: "bos",
        required: false,
      },
    ],
    [
      {
        name: "Structure",
        field: "structure",
        type: "photo",
        multiple: true,
        required: false,
      },
      {
        name: "Installed Panel",
        field: "installed_panel",
        required: false,
      },
      {
        name: "Tower & Structure",
        field: "tower_structure_installed",
        required: false,
      },
      {
        name: "Installed Turbine",
        field: "installed_turbine",
        required: false,
      },
      {
        name: "Controller",
        field: "controller",
        required: false,
      },
      {
        name: "Installed Inverter",
        field: "installed_inverter",
        required: false,
      },
      {
        name: "ACDB/DCDB",
        field: "acdb_dcdb",
        required: false,
      },
      {
        name: "Installed battery",
        field: "installed_battery",
        required: false,
      },
      {
        name: "Battery stand photo",
        field: "battery_stand_photo",
        required: false,
      },
      {
        name: "Earthing Status(LA)",
        field: "earthing_status_la",
        required: false,
      },
      {
        name: "Earthing Status(Structure Earthing)",
        field: "earthing_status_structure_earthing",
        required: false,
      },
      {
        name: "Earthing Status(Earthing Pit)",
        field: "earthing_status_earthing_pit",
        required: false,
      },
      {
        name: "Earthing Status(Panel to Panel Earthing)",
        field: "earthing_status_panel_to_panel_earthing",
        required: false,
      },
      {
        name: "Earthing Status(AC/Inverter Earthing)",
        field: "earthing_status_ac_inverter_earthing",
        required: false,
      },
      {
        name: "Video Upload",
        field: "video_upload",
        required: false,
      },
    ],
    [
      {
        name: "Request for QC",
        field: "request_for_qc",
        required: false,
      },
      {
        name: "MSEB QC VISIT",
        field: "mseb_qc_visit",
        required: false,
      },
      {
        name: "MSEB NOC",
        field: "mseb_noc",
        required: false,
      },
      {
        name: "Meter Testing Request",
        field: "meter_testing_request",
        required: false,
      },
      {
        name: "MSEB Testing Report",
        field: "mseb_testing_report",
        required: false,
      },
      {
        name: "MESB Release Letter",
        field: "mesb_release_letter",
        required: false,
      },
      {
        name: "MSEB Meter Installation",
        field: "mseb_meter_installation",
        required: false,
      },
      {
        name: "Send to Billing",
        field: "send_to_billing",
        required: false,
      },
    ],
  ];

  const HybridSolarRow = [
    [
      {
        name: "MSEB Agreement",
        field: "mseb_agreement",
        required: false,
      },
      {
        name: "Authorisation Letter",
        field: "authorisation_letter",
        required: false,
      },
      {
        name: "Electrical Contractor Licence",
        field: "electrical_contractor_licence",
        required: false,
      },
      {
        name: "MSEB Payment Receipts",
        field: "mseb_payment_receipts",
        required: false,
      },
      {
        name: "National Portal Acknowledgment Document",
        field: "national_portal_acknowledgment_document",
        required: false,
      },
      {
        name: "Technical Feasibility Document",
        field: "technical_feasibility_document",
        required: false,
      },
      {
        name: "KYC",
        field: "kyc",
        required: false,
      },
      {
        name: "Other",
        field: "other",
        required: false,
      },
    ],
    [
      {
        name: "MSEB Application",
        field: "mseb_application",
        required: false,
      },
      {
        name: "Subsidy Application",
        field: "subsidy_application",
        required: false,
      },
    ],
    [
      {
        name: "Site Visit 1",
        field: "site_visit_1",
        required: false,
      },
      {
        name: "Site Visit 2",
        field: "site_visit_2",
        required: false,
      },
    ],
    [
      {
        name: "SLD",
        field: "sld",
        required: false,
      },
      {
        name: "Plant Design",
        field: "plant_design",
        required: false,
      },
      {
        name: "Inverter Data Sheet",
        field: "inverter_data_sheet",
        required: false,
      },
      {
        name: "Panel Data Sheet",
        field: "panel_data_sheet",
        required: false,
      },
    ],
    [
      {
        name: "Solar Panel",
        field: "solar_panel",
        required: true,
      },
      {
        name: "Solar Panel Serial",
        field: "solar_panel_serial",
        required: false,
      },
      {
        name: "Inverter",
        field: "inverter",
        required: true,
      },
      {
        name: "Inverter Serial",
        field: "inverter_serial",
        required: false,
      },
      {
        name: "Structure",
        field: "structure",
        required: true,
      },
      {
        name: "Cables",
        field: "cables",
        required: false,
      },
      {
        name: "DCR certificate",
        field: "dcr_certificate",
        required: false,
      },
      {
        name: "Battery",
        field: "battery",
        required: true,
      },
      {
        name: "Battery Serial #",
        field: "battery_serial",
        required: false,
      },
      {
        name: "BOS",
        field: "bos",
        required: false,
      },
    ],
    [
      {
        name: "Structure",
        field: "structure_photo",
        type: "photo",
        multiple: true,
        required: false,
      },
      {
        name: "Installed Panel",
        field: "installed_panel",
        required: false,
      },
      {
        name: "Installed Inverter",
        field: "installed_inverter",
        required: false,
      },
      {
        name: "ACDB/DCDB",
        field: "acdb_dcdb",
        required: false,
      },
      {
        name: "Installed battery",
        field: "installed_battery",
        type: "photo",
        multiple: true,
        required: false,
      },
      {
        name: "Battery stand photo",
        field: "battery_stand_photo",
        type: "photo",
        multiple: true,
        required: false,
      },
      {
        name: "Earthing Status(LA)",
        field: "earthing_status_la",
        required: false,
      },
      {
        name: "Earthing Status(Structure Earthing)",
        field: "earthing_status_structure_earthing",
        required: false,
      },
      {
        name: "Earthing Status(Earthing Pit)",
        field: "earthing_status_earthing_pit",
        required: false,
      },
      {
        name: "Earthing Status(Panel to Panel Earthing)",
        field: "earthing_status_panel_to_panel_earthing",
        required: false,
      },
      {
        name: "Earthing Status(AC/Inverter Earthing)",
        field: "earthing_status_ac_inverter_earthing",
        required: false,
      },
      {
        name: "Video Upload",
        field: "video_upload",
        type: "video",
        multiple: false,
        required: false,
      },
    ],
    [
      {
        name: "Request for QC",
        field: "request_for_qc",
        required: false,
      },
      {
        name: "MSEB QC VISIT",
        field: "mseb_qc_visit",
        required: false,
      },
      {
        name: "MSEB NOC",
        field: "mseb_noc",
        required: false,
      },
      {
        name: "Meter Testing Request",
        field: "meter_testing_request",
        required: false,
      },
      {
        name: "MSEB Testing Report",
        field: "mseb_testing_report",
        required: false,
      },
      {
        name: "MESB Release Letter",
        field: "mesb_release_letter",
        required: false,
      },
      {
        name: "MSEB Meter Installation",
        field: "mseb_meter_installation",
        required: false,
      },
      {
        name: "Send to Billing",
        field: "send_to_billing",
        required: false,
      },
    ],
  ];

  const WindOffrows = [
    [
      {
        name: "MSEB Agreement",
        field: "mseb_agreement",
        required: false,
      },
      {
        name: "Authorisation Letter",
        field: "authorisation_letter",
        required: false,
      },
      {
        name: "Electrical Contractor Licence",
        field: "electrical_contractor_licence",
        required: false,
      },
      {
        name: "MSEB Payment Receipts",
        field: "mseb_payment_receipts",
        required: false,
      },
      {
        name: "Technical Feasibility Document",
        field: "technical_feasibility_document",
        required: false,
      },
      {
        name: "KYC",
        field: "kyc",
        required: false,
      },
      {
        name: "Other",
        field: "other",
        required: false,
      },
    ],
    [
      {
        name: "MSEB Application",
        field: "mseb_application",
        required: false,
      },
    ],
    [
      {
        name: "Site Visit 1",
        field: "site_visit_1",
        required: false,
      },
      {
        name: "Site Visit 1",
        field: "site_visit_2",
        required: false,
      },
    ],
    [
      {
        name: "SLD",
        field: "sld",
        required: false,
      },
      {
        name: "Tower Design",
        field: "tower_design",
        required: false,
      },
      {
        name: "Inverter Data Sheet",
        field: "inverter_data_sheet",
        required: false,
      },
      {
        name: "Wind Turbine Data Sheet",
        field: "wind_turbine_data_sheet",
        required: false,
      },
    ],
    [
      {
        name: "Wind Turbine",
        field: "wind_turbine",
        required: true,
      },
      {
        name: "Wind Turbine Serial",
        field: "wind_turbine_serial",
        required: false,
      },
      {
        name: "Inverter",
        field: "inverter",
        required: true,
      },
      {
        name: "Inverter Serial",
        field: "inverter_serial",
        required: false,
      },
      {
        name: "Tower & Structure",
        field: "tower_structure",
        required: true,
      },
      {
        name: "Cables",
        field: "cables",
        required: false,
      },
      {
        name: "DCR Certificate",
        field: "dcr_certificate",
        required: false,
      },
      {
        name: "Meter",
        field: "meter",
        required: false,
      },
      {
        name: "BOS",
        field: "bos",
        required: false,
      },
    ],
    [
      {
        name: "Tower & Structure",
        field: "tower_structure_photo",
        type: "photo",
        multiple: true,
        required: false,
      },
      {
        name: "Controller",
        field: "controller",
        required: false,
      },
      {
        name: "Installed Inverter",
        field: "installed_inverter",
        required: false,
      },
      {
        name: "ACDB/DCDB",
        field: "acdb_dcdb",
        required: false,
      },
      {
        name: "Earthing Status(LA)",
        field: "earthing_status_la",
        required: false,
      },
      {
        name: "Earthing Status(Structure Earthing)",
        field: "earthing_status_structure_earthing",
        required: false,
      },
      {
        name: "Earthing Status(Earthing Pit)",
        field: "earthing_status_earthing_pit",
        required: false,
      },
      {
        name: "Earthing Status(Panel to Panel Earthing)",
        field: "earthing_status_panel_to_panel_earthing",
        required: false,
      },
      {
        name: "Earthing Status(AC/Inverter Earthing)",
        field: "earthing_status_ac_inverter_earthing",
        required: false,
      },
      {
        name: "Video Upload",
        field: "video_upload",
        required: false,
      },
    ],
    [
      {
        name: "Request for QC",
        field: "request_for_qc",
        required: false,
      },
      {
        name: "MSEB QC VISIT",
        field: "mseb_qc_visit",
        required: false,
      },
      {
        name: "MSEB NOC",
        field: "mseb_noc",
        required: false,
      },
      {
        name: "Meter Testing Request",
        field: "meter_testing_request",
        required: false,
      },
      {
        name: "MSEB Testing Report",
        field: "mseb_testing_report",
        required: false,
      },
      {
        name: "MESB Release Letter",
        field: "mesb_release_letter",
        required: false,
      },
      {
        name: "MSEB Meter Installation",
        field: "mseb_meter_installation",
        required: false,
      },
      {
        name: "Send to Billing",
        field: "send_to_billing",
        required: false,
      },
    ],
  ];

  const WindOnrows = [
    [
      { name: "MSEB Agreement", field: "mseb_agreement" },
      { name: "Authorisation Letter", field: "authorisation_letter" },
      {
        name: "Electrical Contractor Licence",
        field: "electrical_contractor_licence",
      },
      { name: "MSEB Payment Receipts", field: "mseb_payment_receipts" },
      {
        name: "Technical Feasibility Document",
        field: "technical_feasibility_document",
      },
      { name: "KYC", field: "kyc" },
      { name: "Other", field: "other" },
    ],
    [{ name: "MSEB Application", field: "mseb_application" }],
    [
      { name: "Site Visit 1", field: "site_visit_1" },
      { name: "Site Visit 1", field: "site_visit_2" },
    ],
    [
      { name: "SLD", field: "sld" },
      { name: "Tower Design", field: "tower_design" },
      { name: "Inverter Data Sheet", field: "inverter_data_sheet" },
      { name: "Wind Turbine Data Sheet", field: "wind_turbine_data_sheet" },
    ],
    [
      { name: "Wind Turbine", field: "wind_turbine", required: true },
      { name: "Wind Turbine Serial", field: "wind_turbine_serial" },
      { name: "Inverter", field: "inverter", required: true },
      { name: "Inverter Serial", field: "inverter_serial" },
      { name: "Tower & Structure", field: "tower_structure", required: true },
      { name: "Cables", field: "cables" },
      { name: "DCR Certificate", field: "dcr_certificate" },
      { name: "Meter", field: "meter" },
      { name: "BOS", field: "bos" },
    ],
    [
      {
        name: "Tower & Structure",
        field: "tower_structure_photo",
        type: "photo",
        multiple: true,
      },
      { name: "Controller", field: "controller" },
      { name: "Installed Inverter", field: "installed_inverter" },
      { name: "ACDB/DCDB", field: "acdb_dcdb" },
      { name: "Earthing Status(LA)", field: "earthing_status_la" },
      {
        name: "Earthing Status(Structure Earthing)",
        field: "earthing_status_structure_earthing",
      },
      {
        name: "Earthing Status(Earthing Pit)",
        field: "earthing_status_earthing_pit",
      },
      {
        name: "Earthing Status(Panel to Panel Earthing)",
        field: "earthing_status_panel_to_panel_earthing",
      },
      {
        name: "Earthing Status(AC/Inverter Earthing)",
        field: "earthing_status_ac_inverter_earthing",
      },
      { name: "Video Upload", field: "video_upload" },
    ],
    [
      { name: "Request for QC", field: "request_for_qc" },
      { name: "MSEB QC VISIT", field: "mseb_qc_visit" },
      { name: "MSEB NOC", field: "mseb_noc" },
      { name: "Meter Testing Request", field: "meter_testing_request" },
      { name: "MSEB Testing Report", field: "mseb_testing_report" },
      { name: "MESB Release Letter", field: "mesb_release_letter" },
      { name: "MSEB Meter Installation", field: "mseb_meter_installation" },
      { name: "Send to Billing", field: "send_to_billing" },
    ],
  ];

  const SolarOffrows = [
    [
      {
        name: "Electrical Contractor Licence",
        field: "electrical_contractor_licence",
        required: false,
      },
      {
        name: "KYC - Aadhar card, Pan card, Ownership proof doc",
        field: "kyc_documents",
        required: false,
      },
      {
        name: "Other",
        field: "other",
        required: false,
      },
    ],
    [
      {
        name: "Site Visit 1",
        field: "site_visit_1",
        required: false,
      },
      {
        name: "Site Visit 1",
        field: "site_visit_2",
        required: false,
      },
    ],
    [
      {
        name: "SLD",
        field: "sld",
        required: false,
      },
      {
        name: "Plant Design",
        field: "plant_design",
        required: false,
      },
      {
        name: "Inverter Data Sheet",
        field: "inverter_data_sheet",
        required: false,
      },
      {
        name: "Panel Data Sheet",
        field: "panel_data_sheet",
        required: false,
      },
    ],
    [
      {
        name: "Solar Panel",
        field: "solar_panel",
        required: true,
      },
      {
        name: "Solar Panel Serial",
        field: "solar_panel_serial",
        required: false,
      },
      {
        name: "Inverter",
        field: "inverter",
        required: false,
      },
      {
        name: "Inverter Serial",
        field: "inverter_serial",
        required: true,
      },
      {
        name: "Structure",
        field: "structure",
        required: true,
      },
      {
        name: "Cables",
        field: "cables",
        required: false,
      },
      {
        name: "DCR certificate",
        field: "dcr_certificate",
        required: false,
      },
      {
        name: "Battery",
        field: "battery",
        required: true,
      },
      {
        name: "Battery Serial #",
        field: "battery_serial",
        required: true,
      },
      {
        name: "BOS",
        field: "bos",
        required: false,
      },
    ],
    [
      {
        name: "Structure",
        field: "structure_photo",
        type: "photo",
        multiple: true,
        required: false,
      },
      {
        name: "Installed Panel",
        field: "installed_panel",
        required: false,
      },
      {
        name: "Installed Inverter",
        field: "installed_inverter",
        required: false,
      },
      {
        name: "ACDB/DCDB",
        field: "acdb_dcdb",
        required: false,
      },
      {
        name: "Earthing Status(LA)",
        field: "earthing_status_la",
        required: false,
      },
      {
        name: "Earthing Status(Structure Earthing)",
        field: "earthing_status_structure_earthing",
        required: false,
      },
      {
        name: "Earthing Status(Earthing Pit)",
        field: "earthing_status_earthing_pit",
        required: false,
      },
      {
        name: "Earthing Status(Panel to Panel Earthing)",
        field: "earthing_status_panel_to_panel_earthing",
        required: false,
      },
      {
        name: "Earthing Status(AC/Inverter Earthing)",
        field: "earthing_status_ac_inverter_earthing",
        required: false,
      },
      {
        name: "Video Upload",
        field: "video_upload",
        type: "video",
        multiple: false,
        required: false,
      },
    ],
  ];

  const SolarOnrows = [
    [
      { name: "MSEB Agreement", field: "mseb_agreement", required: false },
      {
        name: "Authorisation Letter",
        field: "authorisation_letter",
        required: false,
      },
      {
        name: "Electrical Contractor Licence",
        field: "electrical_contractor_licence",
        required: false,
      },
      {
        name: "MSEB Payment Receipts",
        field: "mseb_payment_receipts",
        required: false,
      },
      {
        name: "National Portal Acknowledgment Document",
        field: "national_portal_acknowledgment_document",
        required: false,
      },
      {
        name: "Technical Feasibility Document",
        field: "technical_feasibility_document",
        required: false,
      },
      { name: "KYC", field: "kyc", required: false },
      { name: "Other", field: "other", required: false },
    ],
    [
      { name: "MSEB Application", field: "mseb_application", required: false },
      {
        name: "Subsidy Application",
        field: "subsidy_application",
        required: false,
      },
    ],
    [
      { name: "Site Visit 1", field: "site_visit_1", required: false },
      { name: "Site Visit 2", field: "site_visit_2", required: false },
    ],
    [
      { name: "SLD", field: "sld", required: false },
      { name: "Plant Design", field: "plant_design", required: false },
      {
        name: "Inverter Data Sheet",
        field: "inverter_data_sheet",
        required: false,
      },
      { name: "Panel Data Sheet", field: "panel_data_sheet", required: false },
    ],
    [
      { name: "Solar Panel", field: "solar_panel", required: true },
      { name: "Solar Panel Serial", field: "solar_panel_serial" },
      { name: "Inverter", field: "inverter", required: true },
      { name: "Inverter Serial", field: "inverter_serial" },
      { name: "Structure", field: "structure", required: true },
      { name: "Cables", field: "cables", required: false },
      { name: "DCR certificate", field: "dcr_certificate", required: false },
      { name: "Meter", field: "meter", required: false },
      { name: "BOS", field: "bos", required: false },
    ],
    [
      {
        name: "Structure",
        field: "structure_photo",
        type: "photo",
        multiple: true,
        required: false,
      },
      {
        name: "Installed Panel",
        field: "installed_panel",
        required: false,
      },
      {
        name: "Installed Inverter",
        field: "installed_inverter",
        required: false,
      },
      {
        name: "ACDB/DCDB",
        field: "acdb_dcdb",
        required: false,
      },
      {
        name: "Earthing Status(LA)",
        field: "earthing_status_la",
        required: false,
      },
      {
        name: "Earthing Status(Structure Earthing)",
        field: "earthing_status_structure_earthing",
        required: false,
      },
      {
        name: "Earthing Status(Earthing Pit)",
        field: "earthing_status_earthing_pit",
        required: false,
      },
      {
        name: "Earthing Status(Panel to Panel Earthing)",
        field: "earthing_status_panel_to_panel_earthing",
        required: false,
      },
      {
        name: "Earthing Status(AC/Inverter Earthing)",
        field: "earthing_status_ac_inverter_earthing",
        required: false,
      },
      {
        name: "Video Upload",
        field: "video_upload",
        type: "video",
        multiple: false,
        required: false,
      },
    ],
  ];

  const handleFileChange = async (event, itemField) => {
    const file = event.target.files[0];
    if (file) {
      await handleUpload(file, itemField);
    }
  };

  const handleUpload = async (file, itemField) => {
    setUploading(true);
    setError(null);
    const accessToken = localStorage.getItem("Authorization");

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", itemField);
      formData.append("project_no", projectId);

      const response = await uploadProjectFile(formData, accessToken);
      console.log(response);
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Error uploading file");
    }

    setUploading(false);
  };

  const getFileStatusByType = (type) => {
    const defaultResponse = { status: "NA", date: "NA" };
    if (!Array.isArray(fileStatus)) {
      return defaultResponse;
    }

    const file =
      fileStatus?.find((file) => file.type === type) ?? defaultResponse;

    return {
      status: file.status,
      date:
        file.status === "OPEN" || file.status === "approve"
          ? file.addedOn
          : "NA",
    };
  };

  const logFileStatuses = () => {
    const statuses = techRows[activeSection]
      ?.filter((item) => item.required === true) // Filter for items where required is true
      .map((item) => {
        const file = fileStatus?.find((f) => f.type === item.field);

        return file ? ` ${file.status}` : `NA`;
      });

    setSectionFlag(!statuses?.includes("NA"));
  };

  const handleNextSection = () => {
    if (activeSection < techSections.length - 1) {
      setActiveSection((prevActiveSection) => prevActiveSection + 1);
    }
  };

  const handlePreviousSection = () => {
    if (activeSection > 0) {
      setActiveSection((prevActiveSection) => prevActiveSection - 1);
    }
  };

  const getUploadedFile = async (field) => {
    const file = fileStatus?.find((f) => f.type === field);
    const accessToken = localStorage.getItem("Authorization");

    try {
      const response = await getProjectFile(file.key, accessToken);
      window.open(response, "_blank");
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Error uploading file");
    }
  };

  useEffect(() => {}, [approvalStates]);

  useEffect(() => {
    logFileStatuses();
  }, [activeSection, fileStatus, sectionFlag]);

  useEffect(() => {
    if (section === "solar-on-grid") {
      setTechSections(solarOnGrid);
      setTechRows(SolarOnrows);
    } else if (section === "solar-off-grid") {
      setTechSections(solarOffGrid);
      setTechRows(SolarOffrows);
    } else if (section === "wind-on-grid") {
      setTechSections(windOnGrid);
      setTechRows(WindOnrows);
    } else if (section === "wind-off-grid") {
      setTechSections(windOffGrid);
      setTechRows(WindOffrows);
    } else if (section === "hybrid-solar") {
      setTechSections(hybridSolar);
      setTechRows(HybridSolarRow);
    } else if (section === "hybrid-wind") {
      setTechSections(hybridWind);
      setTechRows(HybridWindRow);
    } else if (section === "hybrid") {
      setTechSections(hybridSolarWind);
      setTechRows(HybridWindSolarRow);
    } else {
      navigate("/not-found");
    }
  }, []);

  useEffect(() => {
    const getFiles = async () => {
      const accessToken = localStorage.getItem("Authorization");
      try {
        const response = await getProjectFileStatus(projectId, accessToken);
        setFileStatus(response);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
        } else {
          setError("An error occurred. Please try again later.");
        }
      }
    };
    getFiles();
  }, [uploading]);

  return (
    <Box display="flex">
    <Box mt={12} ml={6} mr={3}>
      <Card sx={{ p: 3, mb: 3 }}>
        <Box display="flex" mt={3} ml={3} mr={3} mb={3}>
          {techSections?.map((section, index) => (
            <Box display="flex" width="100%" gap={12} key={index}>
              <Box textAlign="center" sx={{ cursor: 'pointer' }}>
                <img
                  src={activeSection === index ? "/check-point.png" : "/uncheck-point.png"}
                  alt={section}
                  className="w-4 mx-auto mb-2"
                  style={{ cursor: 'pointer' }}
                />
                <Typography variant="body2" className={activeSection === index ? "font-bold" : ""}>
                  {section}
                </Typography>
              </Box>
              {index < 6 && (
                <hr className="border-gray-300 w-full mt-2 items-center justify-center" />
              )}
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="space-between" mt={3} ml={3} mr={3} mb={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePreviousSection}
            disabled={activeSection === 0}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color={sectionFlag ? "primary" : "grey"}
            onClick={handleNextSection}
            disabled={!sectionFlag}
          >
            Next
          </Button>
        </Box>
        <Box ml={3} mr={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: 16, fontWeight: 'bold' }}>Document Checklist</TableCell>
                <TableCell></TableCell>
                <TableCell sx={{ fontSize: 16, fontWeight: 'bold' }}>Uploaded Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!uploading && techRows[activeSection]?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>
                      {item.name}{" "}
                      <span style={{ color: 'red' }}>
                        {item.required ? "*" : ""}
                      </span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => document.getElementById(`fileInput-${index}`).click()}
                      sx={{ mr: 1, backgroundColor: '#074173' }}
                    >
                      Upload
                      <input
                        type="file"
                        id={`fileInput-${index}`}
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileChange(e, item.field)}
                      />
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => getUploadedFile(item.field)}
                      sx = {{ backgroundColor: '#C147E9', mr: 1 }}
                    >
                      View
                    </Button>
                    {/* {(getFileStatusByType(item.field).status === "approve" ||
                      getFileStatusByType(item.field).status === "OPEN") && (
                      <Button
                        variant="contained"
                        color={getFileStatusByType(item.field).status !== "approve" && !approvalStates[item.field] ? "error" : "success"}
                        onClick={() => approveStatus(item.field)}
                      >
                        Approve
                      </Button>
                    )} */}
                  </TableCell>
                  <TableCell>{getFileStatusByType(item.field).date || "NA"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
      </Card>
      {modal ? (
        <ChatModal projectNo={projectId} setModal={setModal} />
      ) : (
        <Box position="fixed" bottom={10} right={10} zIndex={50}>
          <Button
            onClick={() => setModal(true)}
            sx={{
              width: 64,
              height: 64,
              borderRadius: '50%',
              backgroundColor: 'grey.800',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img src="/chat.png" alt="chat" style={{ width: 32, height: 32 }} />
          </Button>
        </Box>
      )}
    </Box>
  </Box>
    // <div>
    //   <div className="flex justify-center w-screen mx-auto mt-20">
    //     <div>
    //       <div className=" w-full">
    //         <div className="flex">
    //           {techSections?.map((section, index) => (
    //             <div className="flex w-full gap-20" key={index}>
    //               <div className="w-fit text-center cursor-pointer">
    //                 <img
    //                   src={
    //                     activeSection === index
    //                       ? "/check-point.png"
    //                       : "/uncheck-point.png"
    //                   }
    //                   alt={section}
    //                   className="cursor-pointer w-4 mx-auto mb-2"
    //                 />
    //                 <span
    //                   className={`text-xs ${
    //                     activeSection === index && "font-bold"
    //                   }`}
    //                 >
    //                   {section}
    //                 </span>
    //               </div>
    //               {index < 6 && (
    //                 <hr className="border-gray-300 w-full mt-2 items-center justify-center" />
    //               )}
    //             </div>
    //           ))}
    //         </div>
    //         <div className="flex justify-between mb-5 mt-5">
    //           <button
    //             className="rounded-md bg-green-500 text-white border-none px-4 py-2"
    //             onClick={handlePreviousSection}
    //             disabled={activeSection === 0}
    //           >
    //             Previous
    //           </button>
    //           <button
    //             className={`rounded-md text-white border-none px-4 py-2 ${
    //               sectionFlag ? "bg-green-500" : "bg-gray-500"
    //             }`}
    //             onClick={handleNextSection}
    //             disabled={!sectionFlag}
    //           >
    //             Next
    //           </button>
    //         </div>
    //         <div className="overflow-x-auto">
    //           <table className="table-auto w-full">
    //             <thead className="text-left">
    //               <tr>
    //                 <th className="px-4 py-2 w-2/3">Document Checklist</th>
    //                 <th className="px-4 py-2"></th>
    //                 <th className="px-4 py-2">Upload Date</th>
    //               </tr>
    //             </thead>
    //             <tbody>
    //               {!uploading &&
    //                 techRows[activeSection]?.map((item, index) => (
    //                   <tr key={index}>
    //                     <td className="px-4 py-2">
    //                       <p>
    //                         {item.name}{" "}
    //                         <span className="text-red-500">
    //                           {item.required ? "*" : ""}
    //                         </span>
    //                       </p>
    //                     </td>
    //                     <td>
    //                       <button
    //                         className="rounded-md bg-green-500 my-1 text-white border-none w-fit px-2 py-1 text-sm mr-2"
    //                         onClick={() =>
    //                           document
    //                             .getElementById(`fileInput-${index}`)
    //                             .click()
    //                         }
    //                       >
    //                         Upload
    //                         <input
    //                           type="file"
    //                           id={`fileInput-${index}`}
    //                           className="hidden"
    //                           onChange={(e) => handleFileChange(e, item.field)}
    //                         />
    //                       </button>
    //                       <button
    //                         onClick={() => getUploadedFile(item.field)}
    //                         className="rounded-md my-1 bg-blue-800 text-white border-none w-fit px-2 py-1 text-sm mr-2"
    //                       >
    //                         View
    //                       </button>
    //                     </td>
    //                     <td className="px-4 py-2">
    //                       {getFileStatusByType(item.field).date || "NA"}
    //                     </td>
    //                   </tr>
    //                 ))}
    //             </tbody>
    //           </table>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {modal ? (
    //     <ChatModal projectNo={projectId} setModal={setModal} />
    //   ) : (
    //     <div className="fixed bottom-10 right-10 z-50 rounded-full shadow-lg">
    //       <button
    //         onClick={() => {
    //           setModal(true);
    //         }}
    //         className="w-16 h-16 rounded-full bg-secondary text-white border-none cursor-pointer flex justify-center items-center"
    //       >
    //         <img src="/message.png" alt="chat" className="w-8 h-8" />
    //       </button>
    //     </div>
    //   )}
    // </div>
  );
};
export default Project;
