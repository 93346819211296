import React, { useEffect, useState } from "react";
import RegisterForm from "./RegisterForm";
import LoginForm from "./LoginForm";

const VendorHomeBanner = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeForm, setActiveForm] = useState(true);
  const [mobileNo, setMobileNo] = useState(0);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleMobileChange = (event) => {
    setMobileNo(event.target.value);
  };

  useEffect(() => {}, [activeForm]);

  return (
    <div className="flex bg-secondary mt-2 text-left">
      {/* Background Overlay with Blurred Effect */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full backdrop-filter backdrop-blur-sm z-10"></div>
      )}
      {/* Left Column */}
      <div className="w-3/5 text-white p-16">
        <p className="text-6xl font-bold">
          EnergyBae CRM 
        </p>
        <p className="text-6xl font-bold text-sky-800">Platform</p>

        <ul className="list-disc pl-4">
          <li className="mt-6 text-2xl">
          Get qualified and genuine leads in your city
          </li>
          <li className="text-2xl">Reduce deal closing time</li>
          <li className="text-2xl">Manage all your deals at one place</li>
          <li className="text-2xl">Get Easy Financing for your Projects</li>
        </ul>

        <div className="flex mt-6 gap-2">
          {/* Input box for phone number */}
          <input
            type="tel"
            maxLength={10}
            pattern="/^(0|91)?[6-9][0-9]{9}$/"
            name="phone"
            onChange={handleMobileChange}
            placeholder="Enter Phone Number"
            className="w-64 shadow-xl py-2.5 px-4 outline-none rounded-lg text-black bg-white placeholder:text-gray-500"
          />

          {/* "Get Started" button */}
          <button
            onClick={openModal}
            className="bg-blue-800 text-white py-2 px-4 rounded-3xl shadow-xl"
          >
            Get Started
          </button>
        </div>
      </div>

      {/* Right Column */}
      <div className="p-16"></div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-10">
          <div className="bg-white p-8 rounded-2xl shadow-2xl relative">
            {/* Heading */}
            <div className="flex items-center justify-between mb-4">
              <h1 className="text-xl font-semibold">
                {activeForm ? "Complete Registration to Begin!" : "Login"}
              </h1>
              <img
                src="/close.png"
                alt="Close"
                className="h-8 w-8"
                onClick={closeModal}
              />
            </div>

            {activeForm ? (
              <RegisterForm
                mobileNo={mobileNo}
                setActiveForm={setActiveForm}
                closeModal={closeModal}
              />
            ) : (
              <LoginForm
                setActiveForm={setActiveForm}
                closeModal={closeModal}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorHomeBanner;
