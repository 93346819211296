
exports.checksum = (g) => {
    let regTest = /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/.test(g)
    return regTest
}


//console.log(checksum("27AAFCB7707D1c5"));


