import React, { useState } from "react";
import ChatModal from "../Dashboard/ChatModal";
import ProjectTracker from "../../Routes/Vendor/Dashboard/ProjectTracker";

const Test = () => {
  return (
    <div>
      <ProjectTracker />
    </div>
  );
};

export default Test;
