import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { getProjects } from "../../../api";
import {
Box,
Card
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../components/Common/CustomTable";



const Projects = (props) => {
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
  };

  const checkCapacity = (technology, solarCapacity, windCapacity) => {
    const curr = technology?.split("-")[0];
    if (curr === "solar") {
      return solarCapacity;
    } else if (curr === "wind") {
      return windCapacity;
    } else {
      return solarCapacity + "," + windCapacity;
    }
  };

  useEffect(() => {
    const fetchVendorLeads = async () => {
      const accessToken = localStorage.getItem("Authorization");
      try {
        const response = await getProjects(
          currentPage - 1,
          itemsPerPage,
          accessToken
        );
        setTotalPages(response.totalPages);
        setLeads(response.content);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
        } else {
          setError("An error occurred. Please try again later.");
        }
      }
    };
    fetchVendorLeads();
  }, [currentPage]);

  const handleEdit = (row) => {
    navigate(`/dashboard/projects/${row.leadId}?section=${row.technology}`); 
  };

  const columns = [
    { Header: 'Customer Name', accessor: 'customerName' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Location', accessor: 'location' },
    { Header: 'Capacity', accessor: 'capacity' },
    { Header: 'Technology', accessor: 'technology' },
    { Header: 'Publish Date', accessor: 'timestamp' },
  ];

  return (
    <div className="flex h-full w-full">
      <Box p={3} mt={6} sx={{ width: '100%' }}>
      <main>
        <Card>
        <CustomTable columns={columns} data={leads} onEdit={handleEdit} editable={true} />
        </Card>
      </main>
      </Box>
    </div>
  );

  // return (
  //   <div className="mt-11 p-5">
  //     <TableContainer component={Paper}>
  //       <Table sx={{ minWidth: 950 }} aria-label="simple table">
  //         <TableHead>
  //           <TableRow>
  //             <TableCell></TableCell>
  //             <TableCell>Customer Name</TableCell>
  //             <TableCell>Status</TableCell>
  //             <TableCell>Location</TableCell>
  //             <TableCell>Capacity</TableCell>
  //             <TableCell>Technology</TableCell>
  //             <TableCell>Publish Date</TableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {leads?.map((row, index) => (
  //             <TableRow key={row.leadId}>
  //               <TableCell>
  //                 <button
  //                   onClick={() => {
  //                     navigate(
  //                       `/dashboard/projects/${row.leadId}?section=${row.technology}`
  //                     );
  //                   }}
  //                   className="bg-blue-800 text-white text-sm px-4 py-2 rounded-lg hover:bg-blue-600 shadow-xl"
  //                 >
  //                   View
  //                 </button>
  //               </TableCell>
  //               <TableCell>
  //                 {row.customerName ? row.customerName ?? "NA" : "NA"}
  //               </TableCell>
  //               <TableCell>{row.status ? row.status ?? "NA" : "NA"}</TableCell>
  //               <TableCell>
  //                 {row.location ? row.location ?? "NA" : "NA"}
  //               </TableCell>
  //               <TableCell>
  //                 {checkCapacity(
  //                   row.technology,
  //                   row.solarCapacity,
  //                   row.windCapacity
  //                 ) ?? "NA"}
  //               </TableCell>
  //               <TableCell>
  //                 {row.technology ? row.technology ?? "NA" : "NA"}
  //               </TableCell>
  //               <TableCell>
  //                 {row.timestamp ? row.timestamp ?? "NA" : "NA"}
  //               </TableCell>
  //             </TableRow>
  //           ))}
  //         </TableBody>
  //       </Table>
  //     </TableContainer>
  //     <div className="mt-4 flex justify-center">
  //       <Pagination
  //         count={totalPages}
  //         page={currentPage}
  //         onChange={handlePaginationChange}
  //         shape="rounded"
  //       />
  //     </div>
  //   </div>
  // );
};

export default Projects;
