import React, { useState } from "react";
import { uploadToStorage } from "../../api";
import { Button, Card, Typography } from "@mui/material";

const UploadProposal = ({ projectNo, fetchUploadedProposals, leadId }) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleUpload(file, projectNo);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const file = droppedFiles[0];
      handleUpload(file);
    }
  };

  const handleUpload = async (file, projectNo) => {
    const accessToken = localStorage.getItem("Authorization");
    if (!projectNo) {
      setError("Please create a project");
      return;
    }

    setUploading(true);

    try {
      const formData = new FormData();
      formData.append("type", "proposal");
      formData.append("project_no", projectNo);
      formData.append("file", file);

      const response = await uploadToStorage(leadId, formData, accessToken);
      console.log(response);
      const vendorId = localStorage.getItem("vendorid");
      fetchUploadedProposals(vendorId);
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Error uploading file");
    }

    setUploading(false);
  };

  return (
    // <Card sx={{ p: 3 }}>
    <div className="mb-6">
     <Typography variant="h5" component="div" className="m-2">
          Upload Proposal
        </Typography>   
    <div className="flex flex-col items-center mt-6">
      <div
        className="border-2 border-dashed rounded-lg bg-gray-100 border-gray-400 p-10 text-center flex flex-col items-center w-4/5"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <p className="text-lg">{uploading ? "Uploading..." : "Drag and drop file here"}</p>
        <input
          id="fileInput"
          type="file"
          onChange={handleFileChange}
          multiple
          className="hidden"
        />
        {!uploading && (
          <Button
            onClick={() => document.getElementById("fileInput").click()}
            variant="outlined"
            color="primary"
          >
            Upload
          </Button>
        )}
      </div>
    </div>
    </div>
    // <div className="flex flex-col items-center mt-6">
    //   <div
    //     className="border-2 border-dashed rounded-lg bg-gray-100 border-gray-400 p-10 text-center flex flex-col items-center w-4/5"
    //     onDragOver={handleDragOver}
    //     onDrop={handleDrop}
    //   >
    //     <p className="text-lg">
    //       {uploading ? "Uploading..." : "Drag and drop file here"}
    //     </p>

    //     <input
    //       id="fileInput"
    //       type="file"
    //       onChange={handleFileChange}
    //       className="hidden"
    //       accept="application/pdf"
    //     />
    //     {!uploading && (
    //       <button
    //         onClick={() => document.getElementById("fileInput").click()}
    //         className="mt-4 bg-secondary text-white py-1 px-4 rounded-2xl"
    //       >
    //         Upload
    //       </button>
    //     )}
    //   </div>
    // </div>
  );
};

export default UploadProposal;
