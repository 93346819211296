import React from "react";
import { Link } from "react-router-dom";

const CustomerSignIn = () => {
  return (
    <div className="bg-white flex flex-row justify-center w-full">
      <div className="bg-white w-[1280px] h-[832px] relative">
        <img
          className="w-[832px] h-full top-0 right-full left-[548px] absolute object-cover"
          alt="solar"
          src="/Solar-image.png"
        />
        <img
          className="w-[283px] h-[54px] top-[153px] left-[129px] absolute object-contain"
          alt="logo"
          src="/logo.png"
        />

        <form>
          <div className="absolute w-[364px] h-[45px] top-[317px] left-[92px] bg-white rounded-[5px] border-2 border-solid border-[#d9d9d9]">
            <input
              type="email"
              className="w-full h-full [font-family:'Nokora-Regular',Helvetica] font-normal text-[#808080] text-[16px] tracking-[0] leading-[normal] p-4"
              placeholder="name@gmail.com"
              required
            />
          </div>
        </form>

        <form>
          <div className="absolute w-[364px] h-[45px] top-[403px] left-[92px] bg-white rounded-[5px] border-2 border-solid border-[#d9d9d9]">
            <input
              type="password"
              className="w-full h-full [font-family:'Nokora-Regular',Helvetica] font-normal text-[#808080] text-[16px] tracking-[0] leading-[normal] p-4"
              placeholder="Atleast 8 characters"
              required
            />
          </div>
        </form>
        <button className="absolute bottom-0 w-60 h-12 top-[490px] left-[155px] bg-green-600 text-white rounded-md cursor-pointer text-base">
          Login
        </button>

        <div className="absolute w-[106px] top-[287px] left-[94px] [font-family:'Nokora-Regular',Helvetica] font-normal text-black text-[18px] tracking-[0] leading-[normal] whitespace-nowrap">
          Email*
        </div>

        <p className="absolute w-[307px] top-[554px] left-[94px] [font-family:'Nokora-Regular',Helvetica] font-normal text-black text-[14px] tracking-[0] leading-[normal] whitespace-nowrap">
          <span className="[font-family:'Nokora-Regular',Helvetica] font-normal text-black text-[14px] tracking-[0]">
            Don't have an account?
          </span>
          <Link to="/signup">
            <span className="[font-family:'Nokora-Bold',Helvetica] font-bold text-[#0088cc] cursor-pointer">
              &nbsp;&nbsp;Signup
            </span>
          </Link>
        </p>
        <div className="my-8 border-b text-center absolute w-[307px] top-[554px] left-[94px] [font-family:'Nokora-Regular">
          <div className="px-3 inline-block text-sm text-black font-medium bg-white transform translate-y-1/2">
            Or
          </div>
        </div>
        <Link to="/forgot-password">
          {" "}
          {/* Use the Link component */}
          <div className="absolute w-[185px] top-[460px] left-[325px] [font-family:'Nokora-Bold',Helvetica] font-bold text-[#448e1a] text-[14px] tracking-[0] leading-[normal]">
            Forgot Password?
          </div>
        </Link>
        <div className="absolute w-[247px] top-[224px] left-[151px] [font-family:'Nokora-Regular',Helvetica] font-normal text-black text-[22px] text-center tracking-[0] leading-[normal] whitespace-nowrap">
          Welcome back!
        </div>
        <div
          className="absolute w-[106px] top-[373px] left-[94px] [font-family:'Nokora-Regular',Helvetica] font-normal text-black text-[18px] tracking-[0] leading-[normal] whitespacesrc
      nowrap"
        >
          Password*
        </div>
        {/* <img className="w-[183px] top-[618px] left-[64px] absolute h-px object-cover" alt="Line" src="line-1.svg" /> */}
        <img
          className="absolute w-[318px] h-[53px] top-[640px] left-[140px] object-cover"
          alt="Screenshot from"
          src="/logGoogle.png"
        />
      </div>
    </div>
  );
};

export default CustomerSignIn;
