import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const RegisterForm = ({ mobileNo, setActiveForm, closeModal }) => {
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: mobileNo ? mobileNo : "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const numericRegex = /^(0|91)?[6-9][0-9]{9}$/;

    const { firstName, lastName, email, mobile, password, confirmPassword } =
      formValues;
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      mobile === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      setError("Please fill in all required fields.");
    } else if (!numericRegex.test(mobile) || mobile === "") {
      setError("Invalid Phone Number");
    } else if (password !== confirmPassword) {
      setError("Passwords do not match.");
    } else if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
    } else if (!/\d/.test(password)) {
      setError("Password must contain at least one digit.");
    } else if (!/[a-zA-Z]/.test(password)) {
      setError("Password must contain at least one letter.");
    } else {
      localStorage.setItem(
        "userData",
        JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          email: email,
          mobile: mobile,
          password: password,
        })
      );
      navigate("/onboard");

      // const userDetails = {
      //   firstname: firstName,
      //   lastname: lastName,
      //   email: email,
      //   mobileNumber: `+91${mobile}`,
      //   password: password,
      // };
      // try {
      //   const response = await axios.post(
      //     `${process.env.REACT_APP_SERVER}/auth/register/vendor`,
      //     userDetails
      //   );

      //   if (response.status === 200) {
      //     localStorage.setItem("Authorization", response.data.access_token);
      //     closeModal();
      //     navigate("/onboard");
      //   } else {
      //     setError(response.data.message);
      //   }
      // } catch (error) {
      //   if (
      //     error.response &&
      //     error.response.data &&
      //     error.response.data.message
      //   ) {
      //     setError(error.response.data.message);
      //   } else {
      //     setError("An error occurred. Please try again later.");
      //   }
      // }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex gap-6">
          {/* First Name */}
          <div className="mt-8">
            <label htmlFor="firstname" className="block text-sm text-gray-700">
              First Name*
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formValues.firstName}
              onChange={handleChange}
              className="mt-1 px-3 py-2 w-full border-2 text-medium  border-gray-300 outline-primary rounded-md"
              placeholder="Enter First Name"
            />
          </div>

          {/* Last Name */}
          <div className="mt-8 w-1/2">
            <label htmlFor="lastname" className="block text-sm text-gray-700">
              Last Name*
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formValues.lastName}
              onChange={handleChange}
              className="mt-1 px-3 py-2 w-full border-2 text-medium  border-gray-300 outline-primary rounded-md"
              placeholder="Enter Last Name"
            />
          </div>
        </div>

        <div className="flex mt-4 gap-6">
          {/* Email */}
          <div className="w-1/2">
            <label htmlFor="email" className="block text-sm text-gray-700">
              Email*
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              className="mt-1 px-3  p-2 w-full border-2 text-medium  border-gray-300 outline-primary rounded-md"
              placeholder="Enter Email"
            />
          </div>

          {/* Phone */}
          <div className="w-1/2">
            <label htmlFor="phone" className="block text-sm text-gray-700">
              Phone*
            </label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              value={formValues.mobile}
              onChange={handleChange}
              maxLength={10}
              pattern="^[6-9]\d{9}$"
              className="mt-1 px-3 p-2 w-full border-2 text-medium  border-gray-300 outline-primary rounded-md"
              placeholder="Enter Phone Number"
            />
          </div>
        </div>

        <div className="flex mt-4 gap-6">
          {/* Password */}
          <div className="w-1/2">
            <label htmlFor="password" className="block text-sm text-gray-700">
              Password*
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formValues.password}
              onChange={handleChange}
              className="mt-1 px-3  p-2 w-full border-2 text-medium  border-gray-300 outline-primary rounded-md"
              placeholder="Enter Password"
            />
          </div>

          {/* Confirm Password */}
          <div className="w-1/2">
            <label
              htmlFor="confirmPassword"
              className="block text-sm text-gray-700"
            >
              Confirm Password*
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formValues.confirmPassword}
              onChange={handleChange}
              className="mt-1 px-3  p-2 w-full border-2 text-medium  border-gray-300 outline-primary rounded-md"
              placeholder="Enter Confirm Password"
            />
          </div>
        </div>

        {error && <p className="text-red-500 mt-2">{error}</p>}

        {/* Register and Cancel Buttons */}
        <div className="flex justify-between items-center mt-5">
          {/* Already registered text */}
          <p className="text-sm text-gray-700">
            Already registered?
            <span
              className="font-bold cursor-pointer text-primary"
              onClick={() => setActiveForm(false)}
            >
              {" "}
              Login
            </span>
          </p>

          {/* Register Button */}
          <button
            type="submit"
            className="bg-blue-800 text-white text-sm px-5 py-2 rounded-3xl hover:bg-blue-600 shadow-xl"
          >
            Register
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
