import React from "react";

const ServiceCard = ({title, Text}) => {
  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg">
      <div className="p-5">
        <img
          className="w-12 h-12 object-cover object-center  mr-4"
          src="/project.svg"
          alt="Card"
        />
        <div className="font-bold text-xl mb-2 mt-3">{title}</div>
        <p className="text-gray-700 text-lg">
          {Text}
        </p>
      </div>
    </div>
  );
};

export default ServiceCard;
