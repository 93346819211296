import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { checksum } from "../../utils/validation";

const OnboardForm = () => {
  const userDetails = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [gst, setGst] = useState(null);
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState({
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    email: userDetails.email,
    mobile: userDetails.mobile,
    companyName: "",
    ownerName: "",
    designation: "",
    address1: "",
    address2: "",
    city: "",
    pincode: "",
    companyType: null,
    areaOfOperation: "",
    rangeOfOperation: "",
    serviceProvided: null,
    otherService: "",
    expertise: null,
    otherExpertise: "",
    subsidy: null,
    workshop: null,
  });

  const options1 = [
    { value: "Partnership", label: "Partnership" },
    { value: "Proprietor", label: "Proprietor" },
    { value: "LLP", label: "LLP" },
    { value: "Private Limited", label: "Private Limited" },
  ];

  const options2 = [
    { value: "Solar EPC", label: "Solar EPC" },
    {
      value: "Solar Installation and Commissioning",
      label: "Solar Installation and Commissioning",
    },
    { value: "Solar AMC", label: "Solar AMC" },
    { value: "Solar Cleaning", label: "Solar Cleaning" },
    { value: "Small Wind Turbine", label: "Small Wind Turbine" },
    { value: "Liasioning", label: "Liasioning" },
  ];

  const options3 = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const options4 = [
    { value: "Residential Solar", label: "Residential Solar" },
    { value: "Commercial Solar", label: "Commercial Solar" },
    { value: "Industrial Solar", label: "Industrial Solar" },
    { value: "Utility-scale Solar", label: "Utility-scale Solar" },
    { value: "BIPV", label: "BIPV" },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCompanyType = (selectedOption) => {
    setFormValues({ ...formValues, companyType: selectedOption });
  };

  const handleService = (selectedOption) => {
    setFormValues({ ...formValues, serviceProvided: selectedOption });
  };

  const handleExpertise = (selectedOption) => {
    setFormValues({ ...formValues, expertise: selectedOption });
  };

  const handleSubsidy = (selectedOption) => {
    setFormValues({ ...formValues, subsidy: selectedOption });
  };

  const handleWorkshop = (selectedOption) => {
    setFormValues({ ...formValues, workshop: selectedOption });
  };

  const handleGst = async (event) => {
    const gstNo = event.target.value.toUpperCase();

    if (checksum(gstNo)) {
      try {
        const response = await axios.get(
          `https://sheet.gstincheck.co.in/check/${process.env.REACT_APP_API_KEY}/${gstNo}`
        );
        console.log(response.data);
        const gstData = response.data;
        if (gstData.flag) {
          setGst(gstData);
          setFormValues({ ...formValues, gstNo });
          setError(null);
        } else {
          setError("GST number not found or invalid.");
          setGst(null);
        }
      } catch (error) {
        console.error("Error verifying GST:", error);
        setError("Error verifying GST number.");
        setGst(null);
      }

      event.target.value = gstNo;
    } else {
      setError("Invalid GST Format");
      setGst(null);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const {
      firstName,
      lastName,
      email,
      mobile,
      companyName,
      gstNo,
      ownerName,
      designation,
      address1,
      address2,
      city,
      pincode,
      companyType,
      areaOfOperation,
      rangeOfOperation,
      serviceProvided,
      expertise,
      subsidy,
      workshop,
    } = formValues;

    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      mobile === "" ||
      companyName === "" ||
      gstNo === "" ||
      ownerName === "" ||
      designation === "" ||
      address1 === "" ||
      address2 === "" ||
      city === "" ||
      pincode === "" ||
      companyType === null ||
      areaOfOperation === "" ||
      rangeOfOperation === "" ||
      serviceProvided === null ||
      expertise === null ||
      subsidy === null ||
      workshop === null
    ) {
      setError("Please fill in all required fields.");
      return; // Exit function early if validation fails
    }

    if (!pincode.match(/^[0-9]{6}$/)) {
      setError("Pincode should be a 6-digit number.");
      return; // Exit function early if validation fails
    }

    if (!gstNo || !checksum(gstNo)) {
      setError("Invalid GST Number.");
      return; // Exit function early if validation fails
    }

    // Proceed with form submission
    const serviceProvidedValues = formValues.serviceProvided.map(
      (option) => option.value
    );
    if (formValues.otherService !== "") {
      serviceProvidedValues.push(formValues.otherService);
    }
    const expertiseValues = formValues.expertise.map((option) => option.value);
    if (formValues.otherExpertise !== "") {
      expertiseValues.push(formValues.otherExpertise);
    }

    const formData = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      gstNo: formValues.gstNo,
      mobileNumber: formValues.mobile,
      companyName: formValues.companyName,
      ownerName: formValues.ownerName,
      designation: formValues.designation,
      address: `${formValues.address1}, ${formValues.address2}`,
      city: formValues.city,
      pincode: formValues.pincode,
      companyType: formValues.companyType.value,
      areaOfOperation: formValues.areaOfOperation,
      rangeOfOperation: formValues.rangeOfOperation,
      serviceProvided: serviceProvidedValues,
      expertise: expertiseValues,
      subsidy: formValues.subsidy.value,
      workshop: formValues.workshop.value,
      pancard: formValues.gstNo.slice(2, -3),
    };

    const userData = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      mobileNumber: `+91${mobile}`,
      password: userDetails.password,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/auth/register/vendor`,
        userData
      );

      if (response.status === 200) {
        localStorage.setItem("Authorization", response.data.access_token);
        try {
          const accessToken = localStorage.getItem("Authorization");
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER}/vendors/onboard`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (response.status === 201) {
            console.log("Vendor Onboarded");
            navigate("/dashboard/leads");
          } else {
            setError(response.data.message);
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setError(error.response.data.message);
          } else {
            setError("An error occurred. Please try again later.");
          }
        }
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again later.");
      }
    }

    // try {
    //   const accessToken = localStorage.getItem("Authorization");
    //   const response = await axios.post(
    //     `${process.env.REACT_APP_SERVER}/vendor/onboard`,
    //     formData,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${accessToken}`,
    //       },
    //     }
    //   );

    //   if (response.status === 201) {
    //     console.log("Vendor Onboarded");
    //     navigate("/dashboard/leads");
    //   } else {
    //     setError(response.data.message);
    //   }
    // } catch (error) {
    //   if (
    //     error.response &&
    //     error.response.data &&
    //     error.response.data.message
    //   ) {
    //     setError(error.response.data.message);
    //   } else {
    //     setError("An error occurred. Please try again later.");
    //   }
    // }
  };

  return (
    <div className="flex-1 p-8 overflow-y-auto">
      <form onSubmit={handleSubmit}>
        <h2 className="text-2xl font-bold mb-4">Welcome Onboard!</h2>
        {/* Contact Person Details */}
        <p className="mt-8 mb-6 text-sm">
          Note:{" "}
          <span className="text-red-500">
            Please complete the onboard form to register successfully.
          </span>
        </p>
        <h3 className="text-lg font-bold mb-4">Contact Person Details</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="mb-4">
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700"
            >
              First Name*
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formValues.firstName}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name*
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formValues.lastName}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email*
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="mobile"
              className="block text-sm font-medium text-gray-700"
            >
              Phone*
            </label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              maxLength={10}
              value={formValues.mobile}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>
        </div>

        {/* Company Details */}
        <h3 className="text-lg font-bold mb-4">Company Details</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="mb-4">
            <label
              htmlFor="companyName"
              className="block text-sm font-medium text-gray-700"
            >
              Name of Company*
            </label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formValues.companyName}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="gstNo"
              className="block text-sm font-medium text-gray-700"
            >
              GST No.*
            </label>
            <input
              type="text"
              id="gstNo"
              name="gstNo"
              maxLength={15}
              onChange={handleGst}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>
          <div className="mt-5 h-10 flex">
            <img
              src={
                gst
                  ? gst.flag === false
                    ? "/not-found.png"
                    : "/tick.png"
                  : "/not-verify.png"
              }
              alt="verify"
              className="mt-3 h-5 w-5"
            />
            {gst?.flag && <p className="mt-3 ml-2 text-sm">{gst.data.lgnm}</p>}
          </div>
        </div>

        {/* Owner Details */}
        <h3 className="text-lg font-bold mb-4">
          Owner/Founder/Director/Partner Details
        </h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="mb-4">
            <label
              htmlFor="ownerName"
              className="block text-sm font-medium text-gray-700"
            >
              Owner/Director/Founder Name*
            </label>
            <input
              type="text"
              id="ownerName"
              name="ownerName"
              value={formValues.ownerName}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="designation"
              className="block text-sm font-medium text-gray-700"
            >
              Designation*
            </label>
            <input
              type="text"
              id="designation"
              name="designation"
              value={formValues.designation}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>
        </div>

        {/* Address */}
        <h3 className="text-lg font-bold mb-4">Address</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="mb-4">
            <label
              htmlFor="addressLine1"
              className="block text-sm font-medium text-gray-700"
            >
              Address Line 1*
            </label>
            <input
              type="text"
              id="addressLine1"
              name="address1"
              value={formValues.address1}
              onChange={handleChange}
              maxLength={50}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="addressLine2"
              className="block text-sm font-medium text-gray-700"
            >
              Address Line 2*
            </label>
            <input
              type="text"
              id="addressLine2"
              name="address2"
              value={formValues.address2}
              onChange={handleChange}
              maxLength={50}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="city"
              className="block text-sm font-medium text-gray-700"
            >
              City*
            </label>
            <input
              type="text"
              id="city"
              name="city"
              value={formValues.city}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="pincode"
              className="block text-sm font-medium text-gray-700"
            >
              Pincode*
            </label>
            <input
              type="text"
              id="pincode"
              name="pincode"
              maxLength={6}
              value={formValues.pincode}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="companyType"
              className="block text-sm font-medium text-gray-700"
            >
              Company Type*
            </label>
            <Select
              id="companyType"
              name="companyType"
              onChange={handleCompanyType}
              options={options1}
              className="w-full mt-1"
            />
          </div>
        </div>

        {/* Preferred Areas of Operation */}
        <h3 className="text-lg font-bold mb-4">
          Preferred Areas of Operation*
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mb-4">
            <label
              htmlFor="area"
              className="block text-sm font-medium text-gray-700"
            >
              Whole Maharashtra, Pune, Mumbai etc.
            </label>
            <input
              type="text"
              id="area"
              name="areaOfOperation"
              value={formValues.areaOfOperation}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>
        </div>

        {/* Range of Operation */}
        <h3 className="text-lg font-bold mb-4">Range of Operation*</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="mb-4">
            <label
              htmlFor="range"
              className="block text-sm font-medium text-gray-700"
            >
              1 KW - 300KW
            </label>
            <input
              type="text"
              id="range"
              name="rangeOfOperation"
              value={formValues.rangeOfOperation}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>
        </div>

        {/* Service Provided */}
        <h3 className="text-lg font-bold mb-4">Service Provided*</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <Select
              id="service"
              name="service"
              options={options2}
              isMulti
              onChange={handleService}
              className="w-full"
            />
          </div>
          <br />
          <div className="mb-4">
            <label
              htmlFor="otherService"
              className="block text-sm font-medium text-gray-700"
            >
              Others if any
            </label>
            <input
              type="text"
              id="otherService"
              name="otherService"
              value={formValues.otherService}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>
        </div>

        {/* Expertise in Solar Installation? */}
        <h3 className="text-lg font-bold mb-4">
          Expertise in Solar Installation?*
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <Select
              id="expertise"
              name="expertise"
              options={options4}
              onChange={handleExpertise}
              isMulti
              className="w-full"
            />
          </div>
          <br />
          <div className="mb-4">
            <label
              htmlFor="otherExpertise"
              className="block text-sm font-medium text-gray-700"
            >
              Others if any
            </label>
            <input
              type="text"
              id="otherExpertise"
              name="otherExpertise"
              value={formValues.otherExpertise}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:border-primary"
            />
          </div>
        </div>

        {/* Subsidy */}
        <h3 className="text-lg font-bold mb-4">
          Are you registered in national portal for the subsidy?*
        </h3>
        <div className="mb-4">
          <Select
            id="subsidy"
            name="subsidy"
            onChange={handleSubsidy}
            options={options3}
            className="w-4/12"
          />
        </div>

        {/* Workshop */}
        <h3 className="text-lg font-bold mb-4">
          Would you be interested in an online 2-hour small wind turbines
          workshop to understand the wind business?*
        </h3>
        <div className="mb-4">
          <Select
            id="workshop"
            name="workshop"
            options={options3}
            onChange={handleWorkshop}
            className="w-4/12"
          />
        </div>
        {error && <p className="text-red-500">{error}</p>}
        <div className="mb-4 text-right">
          <button className="bg-primary border text-white py-1 px-4 rounded-md hover:bg-primary hover:text-white text-lg">
            Let's Onboard
          </button>
        </div>
      </form>
    </div>
  );
};

export default OnboardForm;
