import React, { useEffect, useState } from "react";
import UploadProposal from "./UploadProposal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { uploadedProposals } from "../../api";

const Proposal = ({ projectNo, leadId }) => {
  const [proposals, setProposals] = useState([]);
  const [error, setError] = useState(null);

  const fetchUploadedProposals = async (vendorId) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await uploadedProposals(
        vendorId,
        projectNo,
        accessToken
      );
      console.log(response);
      setProposals(response);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };
  useEffect(() => {
    const vendorId = localStorage.getItem("vendorid");

    fetchUploadedProposals(vendorId);
  }, [projectNo]);
  return (
    <div className="px-2 mt-6">   
        <UploadProposal
          leadId={leadId}
          projectNo={projectNo}
          fetchUploadedProposals={fetchUploadedProposals}
        />
        <Typography variant="h5" component="div" className="m-2 mt-2">
          Uploaded proposals
        </Typography>  
        <TableContainer
          sx={{ width: "100%", mt: 2 }}
          className="mx-auto"
          component={Paper}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No.</TableCell>
                <TableCell>File Name</TableCell>
                <TableCell>Upload Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {proposals?.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    {row.key.substring(row.key.indexOf("_") + 1)}
                  </TableCell>
                  <TableCell>{row.addedOn.split("T")[0]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    </div>
  );
};

export default Proposal;
