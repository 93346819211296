import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import RegisterForm from "../Page/RegisterForm";
import LoginForm from "../Page/LoginForm";
import HomeIcon from '@mui/icons-material/Home';
import { AppBar, Toolbar, IconButton, Box } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isVendor = location.pathname === "/";
  const isServices = location.pathname === "/services";
  const isContact = location.pathname === "/contact";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeForm, setActiveForm] = useState(false);
  const [isLogged, setIsLogged] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleLogOut = () => {
    localStorage.removeItem("Authorization");
    setIsLogged(false);
    navigate("/");
  };

  useEffect(() => {
    const checkLoggedIn = () => {
      const token = localStorage.getItem("Authorization");
      setIsLogged(!!token);
    };

    checkLoggedIn();

    window.addEventListener("storage", checkLoggedIn);

    return () => {
      window.removeEventListener("storage", checkLoggedIn);
    };
  }, []);
  useEffect(() => {}, [activeForm]);

  // return (
  //   <AppBar position="fixed" sx={{ backgroundColor: '#EEF5FF', borderRadius: '20px' }}>
  //     <Toolbar>
  //       <nav style={{ marginLeft: 'auto' }}>          
  //         <ul className="flex gap-11">
  //           <li>
  //             <NavLink 
  //               to="/all-leads" 
  //               className={({ isActive }) => 
  //                 isActive ? 'text-blue-600 hover:text-customText' : 'text-customText hover:text-blue-600'
  //               }
  //             >
  //               <HomeIcon className="mb-1 mr-1" fontSize="small" />
  //               Home
  //             </NavLink>
  //           </li>
  //           <li>
  //             <NavLink 
  //               to="/vendors" 
  //               className={({ isActive }) => 
  //                 isActive ? 'text-blue-600 hover:text-customText' : 'text-customText hover:text-blue-600'
  //               }
  //             >
  //               All Vendors
  //             </NavLink>
  //           </li>
  //           <li>
  //             <NavLink 
  //               to="/metadata" 
  //               className={({ isActive }) => 
  //                 isActive ? 'text-blue-600 hover:text-customText' : 'text-customText hover:text-blue-600'
  //               }
  //             >
  //               Metadata
  //             </NavLink>
  //           </li>
  //         </ul>
  //       </nav>
  //       <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, color: 'black' }}> 
  //         <IconButton color="inherit" onClick={handleLogOut} sx={{ marginLeft: 7 }}>
  //           <LogoutIcon />
  //         </IconButton>
  //       </Box>
  //     </Toolbar>
  //   </AppBar>
  // );

  return (
    <header className="bg-black-800 p-4 flex justify-between items-center">
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full backdrop-filter backdrop-blur-sm z-10"></div>
      )}
      <div className="flex items-center">
        <Link to="/">
          <img src="/logo.png" alt="Logo" className="h-10 w-50 mr-2" />
        </Link>
      </div>

      <div className="flex items-center space-x-10">
        <div className="flex items-center space-x-10">
          <Link to="/About-us">
            <h3
              className={` ${
                isVendor ? "text-primary font-bold" : "text-black"
              } hover:text-primary text-lg`}
            >
              About us
            </h3>
          </Link>
          <Link to="/services">
            <h3
              className={` ${
                isServices ? "text-primary font-bold" : "text-black"
              } hover:text-primary text-lg`}
            >
              Services
            </h3>
          </Link>
          <Link to="/blogs">
            <h3
              className={` ${
                isContact ? "text-primary font-bold" : "text-black"
              } hover:text-primary text-lg`}
            >
              Blogs
            </h3>
          </Link>
        </div>

        {!isLogged ? (
          <button
            onClick={openModal}
            className="bg-transparent border border-primary text-primary py-1 px-4 rounded-md hover:bg-primary hover:text-white text-lg"
          >
            Login
          </button>
        ) : (
          <button
            onClick={handleLogOut}
            className="bg-transparent border border-primary text-primary py-1 px-4 rounded-md hover:bg-primary hover:text-white text-lg"
          >
            Logout
          </button>
        )}

        {/* Modal */}
        {isModalOpen && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-10">
            <div className="bg-white p-8 rounded-2xl shadow-2xl relative">
              {/* Heading */}
              <div className="flex items-center justify-between mb-4">
                <h1 className="text-xl font-semibold">
                  {activeForm ? "Complete Registration to Begin!" : "Login"}
                </h1>
                <img
                  src="/close.png"
                  alt="Close"
                  className="h-8 w-8"
                  onClick={closeModal}
                />
              </div>

              {activeForm ? (
                <RegisterForm
                  setActiveForm={setActiveForm}
                  closeModal={closeModal}
                />
              ) : (
                <LoginForm
                  setActiveForm={setActiveForm}
                  closeModal={closeModal}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
