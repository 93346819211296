import axios from "axios";

const getLeads = async (page, size, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/vendors/leads?action=false&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getPendingLeads = async (page, size, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/projects/getleads?action=true&status=SENT_PROPOSAL&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getLeadById = async (leadId, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/vendors/leads/${leadId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const uploadToStorage = async (leadId, formData, accessToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/vendors/${leadId}/upload-proposal`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const uploadedProposals = async (vendorId, projectNo, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/file/proposals?userId=${vendorId}&projectNo=${projectNo}&type=proposal`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getProfile = async (accessToken) => {
  try {
    const response = await axios.get(`https://backend.energybae.in/profile`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const updateProposalStatus = async (key, projectNo, leadId, accessToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/admin/approve?key=${key}&projectNo=${projectNo}&leadId=${leadId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getProjects = async (page, size, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/projects/getleads?action=true&status=APPROVED&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getProjectFileStatus = async (projectNo, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/file/by-project/${projectNo}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const uploadProjectFile = async (formData, accessToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/file/upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getProjectFile = async (key, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/file/view/${key}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error: ${error.message}`);
    throw error;
  }
};

const sendMessage = async (message, accessToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/chat/admin-vendor-chat`,
      message,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getChats = async (projectNo, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/chat/leads/${projectNo}/chat`,

      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const uploadedAttachments = async (projectNo, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/file/get-site-media?type=site-media&project_no=${projectNo}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

export {
  getLeads,
  getLeadById,
  uploadToStorage,
  uploadedProposals,
  getProfile,
  getPendingLeads,
  updateProposalStatus,
  getProjectFile,
  uploadProjectFile,
  getProjects,
  getProjectFileStatus,
  sendMessage,
  getChats,
  uploadedAttachments,
};
